import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Drawer, Button, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { navigateToLoginURL } from '../../common/navigate';

const useStyles = makeStyles(() => ({
  button: {
    height: 95,
    width: '100%',
  },
  label: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '150%',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  list: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '150%',
    textAlign: 'center',
    color: '#FFFFFF',
    backgroundColor: '#000A14',
  },
}));

export const MobileNavigation = () => {
  const personalDetails = useSelector(
    (state) => state.personalDetails.personalDetails
  );
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  // const calendlyCall = (e) => {
  //   e.preventDefault();
  //   window.showCalendly();
  //   return false;
  // };

  return (
    <>
      <div className="mobile-header">
        <Button
          classes={{ root: classes.button, label: classes.label }}
          startIcon={
            <img
              className="mobile-header-item-ico"
              alt="websites"
              src="websites_laptop.png"
            />
          }
        >
          <Link to="/" className="mobile-header-item-text">
            Websites
          </Link>
        </Button>
        <Button
          classes={{ root: classes.button, label: classes.label }}
          className={`${personalDetails?.testAccount ? '' : 'd-none'}`}
          startIcon={
            <img
              className="mobile-header-item-ico"
              alt="websites"
              src="websites_laptop.png"
            />
          }
        >
          <Link to="/developers" className="mobile-header-item-text">
            Developers
          </Link>
        </Button>
        {/*  <Button
          classes={{ root: classes.button, label: classes.label }}
          startIcon={
            <img
              className="mobile-header-item-ico"
              alt="websites"
              src="websites_laptop.png"
            />
          }
        >
          <Link to="/services" className="mobile-header-item-text">
            Services
          </Link>
        </Button> */}
        <Button
          classes={{ root: classes.button, label: classes.label }}
          startIcon={
            <img
              className="mobile-header-item-ico"
              alt="websites"
              src="LogoMark.png"
            />
          }
        >
          <Link to="/audit" className="mobile-header-item-text">
            Audit
          </Link>
        </Button>
        <Button
          classes={{ root: classes.button, label: classes.label }}
          onClick={() => setOpen(true)}
          startIcon={
            <img
              className="mobile-header-item-ico"
              alt="account"
              src="user_circle.png"
            />
          }
        >
          <div className="mobile-header-item-text">Account</div>
        </Button>
      </div>
      <Drawer anchor="top" open={open} onClose={handleClose}>
        <List className={classes.list}>
          <ListItem className="mobile-header">
            <Button
              classes={{ root: classes.button, label: classes.label }}
              startIcon={
                <img
                  className="mobile-header-item-ico"
                  alt="websites"
                  src="websites_laptop.png"
                />
              }
            >
              <Link
                to="/"
                className="mobile-header-item-text"
                onClick={handleClose}
              >
                Websites
              </Link>
            </Button>
            <Button
              classes={{ root: classes.button, label: classes.label }}
              onClick={() => setOpen(true)}
              startIcon={
                <img
                  className="mobile-header-item-ico"
                  alt="account"
                  src="user_circle.png"
                />
              }
            >
              <div className="mobile-header-item-text">Account</div>
            </Button>
          </ListItem>
          <ListItem button onClick={handleClose}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 3.5L3.5 12.5"
                stroke="#FFFFFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.5 12.5L3.5 3.5"
                stroke="#FFFFFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ListItem>
          <ListItem button>
            <Link
              to="/personal-details"
              className="mobile-header-navigation-popover-item"
              onClick={handleClose}
            >
              Personal Details
            </Link>
          </ListItem>
          {/* <ListItem button>
            <Link
              to="/billing-information"
              className="mobile-header-navigation-popover-item"
              onClick={handleClose}
            >
              Billing Information
            </Link>
          </ListItem> */}
          {/* <ListItem button>
            <Link
              to="/invoices"
              className="mobile-header-navigation-popover-item"
              onClick={handleClose}
            >
              Invoices
            </Link>
          </ListItem> */}
          <ListItem button>
            <Link
              to="/password-reset"
              className="mobile-header-navigation-popover-item"
              onClick={handleClose}
            >
              Password Reset
            </Link>
          </ListItem>
          {/* <ListItem button> */}
          {/*  <Link */}
          {/*    onClick={handleClose} */}
          {/*    to="/email-address" */}
          {/*    className="mobile-header-navigation-popover-item" */}
          {/*  > */}
          {/*    Email Address */}
          {/*  </Link> */}
          {/* </ListItem> */}
          <ListItem button>
            <Link
              onClick={handleClose}
              to={{ pathname: process.env.REACT_APP_HTTP_SUPPORT_ENDPOINT }}
              className="mobile-header-navigation-popover-item"
            >
              Support
            </Link>
          </ListItem>
          {/* <ListItem button>
            <Link
              onClick={calendlyCall}
              className="mobile-header-navigation-popover-item"
            >
              Contact Sales
            </Link>
          </ListItem> */}
          <ListItem button>
            <button
              type="button"
              onClick={() => navigateToLoginURL()}
              className="mobile-header-navigation-popover-item mobile-header-navigation-popover-button"
            >
              Logout
            </button>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
