import {
  GET_ALL_INVITES_SUCCESS,
  GET_TEAM_MEMBERS,
  GET_TEAM_MEMBERS_SUCCESS,
} from '../actionTypes';

const initialState = {
  invitations: [],
  team: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_MEMBERS:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        team: action.value,
        loading: false,
      };
    case GET_ALL_INVITES_SUCCESS:
      return {
        ...state,
        invitations: action.value,
      };
    default:
      return state;
  }
}
