import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/css';

import { Paper } from '@material-ui/core';
import { SnackbarContent } from 'notistack';

import { NOTIFICATION_TYPE } from '../../hooks/useNotification';

import Checkmark, { CheckmarkIcon } from '../Checkmark';

import { ReactComponent as CheckIcon } from '../../img/CheckboxIcon.svg';
import { ReactComponent as CloseIcon } from '../../img/CloseboxIcon.svg';

const greenOutline = css`
  background: #ecfcf2;
  color: #093c2b;

  border: 2px solid rgba(9, 60, 43, 0.32);
`;

const redOutline = css`
  background: rgba(255, 236, 236, 1);
  color: rgba(102, 31, 32, 1);

  border: 2px solid rgba(102, 31, 32, 0.32);
`;

const greenIcon = css`
  border-color: rgba(9, 60, 43, 0.32);
`;
const redIcon = css`
  border-color: rgba(102, 31, 32, 0.32);
`;

// TODO: Add configuration for 'warning' and 'info' message!

const SnackMessage = forwardRef(({ message, variant }, ref) => {
  const outlineStyle =
    variant === NOTIFICATION_TYPE.ERROR ? redOutline : greenOutline;
  const icon = variant === NOTIFICATION_TYPE.ERROR ? CloseIcon : CheckIcon;
  const checkmarkStyle =
    variant === NOTIFICATION_TYPE.ERROR ? redIcon : greenIcon;

  return (
    <Container ref={ref}>
      <OutlineCard className={outlineStyle}>
        <MessageContent>
          <Checkmark
            icon={<CheckmarkIcon Icon={icon} className={checkmarkStyle} />}
          >
            <Title>{message}</Title>
          </Checkmark>
        </MessageContent>
      </OutlineCard>
    </Container>
  );
});

SnackMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  variant: PropTypes.string,
};

SnackMessage.defaultProps = {
  variant: NOTIFICATION_TYPE.SUCCESS,
};

export default SnackMessage;

/** Styled Components */

const MessageContent = styled.div`
  padding: 24px;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
`;

const Container = styled(SnackbarContent)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 600px !important;
  }
`;

const OutlineCard = styled(Paper)`
  box-sizing: border-box;
  border-radius: 12px;
`;
