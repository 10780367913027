import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '680px',
    maxHeight: '709px',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    '&:focus': {
      outline: 'none',
    },

    maxWidth: '95%',
  },
}));

export const ModalPanel = ({
  open,
  handleClose,
  children,
  title,
  subTitle,
  applySub,
  notification,
}) => {
  const classes = useStyles();
  const [showNotificaiton, setShowNotificaiton] = React.useState(true);

  return (
    <Modal
      open={open}
      onClose={() => {
        setShowNotificaiton(true);
        handleClose();
      }}
      className={classes.modal}
      aria-labelledby="modal-panel-title"
      aria-describedby="modal-panel-description"
    >
      <div className={classes.paper}>
        <div className="modal-panel">
          <div className="modal-header">
            <div>
              <h2 id="modal-panel-title" className="modal-panel-title">
                {title}
              </h2>
              {title && (
                <img
                  className={`modal-panel-title-underline ${
                    subTitle || applySub
                      ? 'modal-panel-title-underline-with-sub'
                      : ''
                  }`}
                  src="underline.png"
                  alt="header underline"
                />
              )}
            </div>
            <button
              onClick={handleClose}
              type="button"
              className="modal-close-button"
            >
              <img
                className="modal-close-icon"
                src="close.png"
                alt="close modal"
              />
            </button>
          </div>

          {notification && showNotificaiton && (
            <div className="modal-panel-notification">
              <div className="modal-panel-notification-icon">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  className="modal-panel-notification-icon-close"
                  src="close.png"
                  alt="close modal"
                  onClick={() => setShowNotificaiton(false)}
                />
              </div>
              <div className="modal-panel-notification-text">
                {notification}
              </div>
            </div>
          )}
          {subTitle && <div className="modal-panel-sub-title">{subTitle}</div>}
          {children}
        </div>
      </div>
    </Modal>
  );
};

ModalPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  subTitle: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  applySub: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  notification: PropTypes.string,
};
