import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from '@material-ui/core';
import { HashLink as RouterLink } from 'react-router-hash-link';

const TextLink = (props) => {
  const { to } = props;
  const linkProps = {
    ...props,
    ...(to ? { component: RouterLink } : {}),
  };

  return <StyledLink {...linkProps} color="secondary" />;
};

TextLink.propTypes = {
  to: PropTypes.any,
};

TextLink.defaultProps = {
  to: null,
};

export default TextLink;

/** Styled Components */

const StyledLink = styled(Link)`
  transition: border 0.3s ease-out;
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
  line-height: initial;
  color: inherit;

  display: inline-block;

  &:hover {
    border-bottom-color: #c2f4d7;
  }
`;
