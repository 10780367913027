import firebase from 'firebase/app';
import 'firebase/auth';
import { FIREBASE_CONFIG } from './magicValues';

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDr5D27Sm4-epH49cp8BoerW5RJ_XbLuJI",
//   authDomain: "screener-bot.firebaseapp.com",
//   projectId: "screener-bot",
//   storageBucket: "screener-bot.appspot.com",
//   messagingSenderId: "33449300307",
//   appId: "1:33449300307:web:dedd15cfe4497c0b5391a6",
//   measurementId: "G-NK6S69JR0J"
// };
// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const app = firebase.initializeApp(FIREBASE_CONFIG);
const firebaseAuth = firebase.auth(app);

function parseJwt(token) {
  console.log('token section', token);
  const accessToken = token.split('.');
  const base64Url = accessToken[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    // eslint-disable-next-line no-undef
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function verifyEmail(code) {
  return firebaseAuth.applyActionCode(code);
}

function resetPassword(code, newPassword) {
  return firebaseAuth.confirmPasswordReset(code, newPassword);
}

function updatedPassword(user, newPassword) {
  return firebaseAuth.updatedPassword(user, newPassword);
}

export {
  firebase,
  firebaseAuth,
  parseJwt,
  verifyEmail,
  resetPassword,
  updatedPassword,
};
