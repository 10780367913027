import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/react';

const ModalBase = ({ children, open }) => {
  const el = useMemo(() => document.createElement('div'), []);
  React.useEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, [el]);

  return open ? (
    <>
      <Global
        styles={css`
          body {
            padding-right: 15px;
            overflow: hidden;
          }
        `}
      />
      {ReactDOM.createPortal(children, el)}
    </>
  ) : null;
};

ModalBase.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
};

ModalBase.defaultProps = {
  children: null,
  open: false,
};

export default ModalBase;
