import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import React, { PureComponent } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  // CartesianGrid,
  // Tooltip,
  ResponsiveContainer,
} from 'recharts';

// import moment from 'moment';
// import {
//   Chart as ChartJS,
//   Tooltip,
//   Legend,
//   LineElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Select,
  MenuItem,
  Grid,
  TextField,
  Box,
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

import { IconButton, PrimaryButton } from './Controls';

// const useStyles = makeStyles(() => ({
//   input: {
//     height: '48px',
//     '& .MuiInputLabel-formControl': {
//       top: '-4px',
//     },
//     '& .MuiOutlinedInput-input': {
//       padding: '14.5px 14px',
//     },
//     '& .PrivateNotchedOutline-root-62': {
//       top: '2px',
//     },
//     marginRight: '16px',
//     width: '100%',
//     fontWeight: 'normal',
//     '& input:disabled': {
//       color: 'rgba(0, 10, 20, 0.65)',
//       backgroundColor: '#E4E7EB',
//     },
//   },
// }));

const DomainInstallationCode = ({
  code,
  domain,
  sendInstructionsClick,
  copyCodeToClipboard,
  loadingHistory,
  roiList,
  labels,
  initialStart,
  initialEnd,
}) => {
  // const classes = useStyles();
  // const [startDate, setStartDate] = useState(initialStart);
  // const [endDate, setEndDate] = useState(initialEnd);

  // const handleStartDateChange = (date) => {
  //   setStartDate(date);
  // };

  // const handleEndDateChange = (date) => {
  //   setEndDate(date);
  // };

  return (
    <>
      <div className="add-website-fields">
        <LineChart labels={labels} roi={roiList} />
      </div>
      <Box mt="48px" />
      {/* <div className="add-website-fields">
        <div className="copy-website-code">
          {!code && (
            <CenterBlock style={{ width: '100%' }}>
              <CircularProgress />
            </CenterBlock>
          )}
        </div>
      </div> */}
      <div className="modal-panel-send-instructions">
        <div>
          <div>Need help?</div>
          <div className="modal-panel-send-instructions-line-2">
            Send these instructions to your website admin.
          </div>
        </div>
        <IconButton
          variant="outlined"
          style={{
            width: '160px',
            height: '48px',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '150%',
          }}
          color="secondary"
          disabled={!code}
          onClick={() => sendInstructionsClick(domain)}
        >
          <div className="website-card-controls-btn-text-no-icon">
            Contact support
          </div>
        </IconButton>
      </div>
    </>
  );
};

const LineChart = ({ labels, roi }) => {
  // const max = (Math.abs(Math.max(...roi)) * 2 || 0.02) * 100;
  // const min = (Math.min(...roi) * 2 || -0.01) * 100;
  const [days, setDays] = useState(7);
  const handleSelectChange = (event) => setDays(event.target.value);

  const data = labels.map((date, i) => {
    const change = roi[i];
    return {
      date: date,
      change,
    };
  });

  console.log(data);

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-end"
      style={{ position: 'relative' }}
    >
      <Grid item style={{ width: '100%', height: 250 }}>
        &nbsp;
        {/* <Line data={{ ...data, datasets: filteredData }} options={options} /> */}
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={200}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="date" />
            <YAxis />
            {/* <Tooltip /> */}
            <Area
              type="monotone"
              dataKey="change"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item style={{ position: 'absolute', top: 0, right: 0 }}>
        <Select value={days} onChange={handleSelectChange}>
          {/* <MenuItem value={1}>1 day</MenuItem> */}
          <MenuItem value={7}>7 days</MenuItem>
          <MenuItem value={30}>30 days</MenuItem>
          <MenuItem value={60}>60 days</MenuItem>
          <MenuItem value={90}>90 days</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

function DateRangePicker({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="start-date-picker"
        label="Start Date"
        value={startDate}
        onChange={handleStartDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change start date',
        }}
      />
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="end-date-picker"
        label="End Date"
        value={endDate}
        onChange={handleEndDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change end date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DomainInstallationCode;

DomainInstallationCode.propTypes = {
  code: PropTypes.any.isRequired,
  domain: PropTypes.string.isRequired,
  sendInstructionsClick: PropTypes.func.isRequired,
  copyCodeToClipboard: PropTypes.func.isRequired,
  loadingHistory: PropTypes.bool.isRequired,
  roiList: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  initialStart: PropTypes.any.isRequired,
  initialEnd: PropTypes.any.isRequired,
};

LineChart.propTypes = {
  roi: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
};

DateRangePicker.propTypes = {
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any.isRequired,
  handleStartDateChange: PropTypes.func.isRequired,
  handleEndDateChange: PropTypes.func.isRequired,
};

const CenterBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
