export const TAB_CHANGED = 'TAB_CHANGED';
export const SET_TOKEN = 'SET_TOKEN';
export const API_ERROR = 'API_ERROR';
export const API_SUCCESS = 'API_SUCCESS';

export const VERIFY_COMPLIANCE = 'VERIFY_COMPLIANCE';
export const CLEAR_COMPLIANCE = 'CLEAR_COMPLIANCE';
export const VERIFY_COMPLIANCE_LOADING = 'VERIFY_COMPLIANCE_LOADING';
export const VERIFY_COMPLIANCE_SUCCESS = 'VERIFY_COMPLIANCE_SUCCESS';

export const VERIFY_INSTALLATION = 'VERIFY_INSTALLATION';
export const VERIFY_INSTALLATION_SUCCESS = 'VERIFY_INSTALLATION_SUCCESS';

export const GET_API_SAMPLE_CODE = 'GET_API_SAMPLE_CODE';
export const GET_API_SAMPLE_CODE_REDESIGN = 'GET_API_SAMPLE_CODE_REDESIGN';
export const GET_API_SAMPLE_CODE_SUCCESS = 'GET_API_SAMPLE_CODE_SUCCESS';

export const GET_DOMAINS = 'GET_DOMAINS';
export const GET_MORE_DOMAINS = 'GET_MORE_DOMAINS';
export const GET_DOMAINS_SUCCESS = 'GET_DOMAINS_SUCCESS';

export const ADD_DOMAIN = 'ADD_DOMAIN';
export const ADD_DOMAIN_SUCCESS = 'ADD_DOMAIN_SUCCESS';

export const EDIT_DOMAIN = 'EDIT_DOMAIN';
export const EDIT_DOMAIN_SUCCESS = 'EDIT_DOMAIN_SUCCESS';

export const DELETE_DOMAIN = 'DELETE_DOMAIN';
export const DELETE_DOMAIN_SUCCESS = 'DELETE_DOMAIN_SUCCESS';

export const GET_DOMAIN_KEYS = 'GET_DOMAIN_KEYS';
export const GET_DOMAIN_KEYS_SUCCESS = 'GET_DOMAIN_KEYS_SUCCESS';

export const ADD_DOMAIN_KEY = 'ADD_DOMAIN_KEY';
export const ADD_DOMAIN_KEY_SUCCESS = 'ADD_DOMAIN_KEY_SUCCESS';

export const EDIT_DOMAIN_KEY = 'EDIT_DOMAIN_KEY';
export const EDIT_DOMAIN_KEY_SUCCESS = 'EDIT_DOMAIN_KEY_SUCCESS';

export const DELETE_DOMAIN_KEY = 'DELETE_DOMAIN_KEY';
export const DELETE_DOMAIN_KEY_SUCCESS = 'DELETE_DOMAIN_KEY_SUCCESS';

export const GET_ACTIVE_SUBSCRIPTIONS = 'GET_ACTIVE_SUBSCRIPTIONS';
export const GET_ACTIVE_SUBSCRIPTIONS_SUCCESS =
  'GET_ACTIVE_SUBSCRIPTIONS_SUCCESS';

export const GET_INACTIVE_SUBSCRIPTIONS = 'GET_INACTIVE_SUBSCRIPTIONS';
export const GET_INACTIVE_SUBSCRIPTIONS_SUCCESS =
  'GET_INACTIVE_SUBSCRIPTIONS_SUCCESS';

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';

export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';

export const GET_PRICES_REQUEST = 'GET_PRICES_REQUEST';
export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';

export const ADD_TRANSACTION_REQUEST = 'ADD_TRANSACTION_REQUEST';
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS';

export const GET_BILLING_PROFILE_REQUEST = 'GET_BILLING_PROFILE_REQUEST';
export const GET_BILLING_PROFILE_SUCCESS = 'GET_BILLING_PROFILE_SUCCESS';

export const UPDATE_BILLING_PROFILE_REQUEST = 'UPDATE_BILLING_PROFILE_REQUEST';
export const UPDATE_BILLING_PROFILE_SUCCESS = 'UPDATE_BILLING_PROFILE_SUCCESS';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';

export const GET_APPEARANCE = 'GET_APPEARANCE';
export const GET_APPEARANCE_SUCCESS = 'GET_APPEARANCE_SUCCESS';
export const UPDATE_APPEARANCE = 'UPDATE_APPEARANCE';

export const GET_ACCESS = 'GET_ACCESS';
export const GET_ACCESS_SUCCESS = 'GET_ACCESS_SUCCESS';
export const UPDATE_ACCESS = 'UPDATE_ACCESS';

export const SEND_INVITE = 'SEND_INVITE';
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';

export const GET_ALL_INVITES = 'GET_ALL_INVITES';
export const GET_ALL_INVITES_SUCCESS = 'GET_ALL_INVITES_SUCCESS';

export const DELETE_INVITE = 'DELETE_INVITE';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';

export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS';
export const GET_TEAM_MEMBERS_SUCCESS = 'GET_TEAM_MEMBERS_SUCCESS';

export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER';
export const DELETE_TEAM_MEMBER_SUCCESS = 'DELETE_TEAM_MEMBER_SUCCESS';
export const GET_DEV_DOMAINS = 'GET_DEV_DOMAINS';
export const GET_DEV_DOMAINS_SUCCESS = 'GET_DEV_DOMAINS_SUCCESS';

export const ADD_DEV_DOMAIN = 'ADD_DEV_DOMAIN';
export const ADD_DEV_DOMAIN_SUCCESS = 'ADD_DEV_DOMAIN_SUCCESS';

export const SET_PERSONAL_DETAILS = 'SET_PERSONAL_DETAILS';
export const SET_PERSONAL_DETAILS_SUCCESS = 'SET_PERSONAL_DETAILS_SUCCESS';

export const GET_TRADES = 'GET_TRADES';
export const GET_TRADES_SUCCESS = 'GET_TRADES_SUCCESS';

export const GET_MORE_TRADES = 'GET_MORE_TRADES';

export const GET_TRADE_HISTORY = 'GET_TRADES_HISTORY';
export const GET_TRADE_HISTORY_SUCCESS = 'GET_TRADE_HISTORY_SUCCESS';
export const SET_EXCHANGE_API = 'SET_EXCHANGE_API';
export const UPDATE_SETTINGS_API = 'UPDATE_SETTINGS_API';
export const TRADE_ENTRY_API = 'TRADE_ENTRY_API';
