import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton } from './Controls';
import { extractVerificationCodeFromUrl } from '../../common/buildURL';
import { resetPassword } from '../../common/firebase';
import { PasswordValidator } from '../../common/helpers';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    width: '100%',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: '99999',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

const validationSchema = Yup.object().shape({
  password: PasswordValidator.required('Password is required'),
});

export const ResetPasswordModal = ({ closeModal }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (values) => {
    const oobCode = extractVerificationCodeFromUrl();

    setIsLoading(true);

    resetPassword(oobCode, values.password)
      .then(() => {
        closeModal('Password Reset Successfully');
      })
      .catch((error) => {
        console.log(error);
        closeModal(
          'Unable to Reset password. Incase you signed up with a Google account, click Login with Google.'
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ password: '' }}
      onSubmit={handleResetPassword}
    >
      {(formik) => (
        <>
          <div>
            <TextField
              className={classes.input}
              label="New password"
              type="password"
              id="password"
              name="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <PrimaryButton
            color="primary"
            style={{ width: 204, fontSize: '16px' }}
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Reset Password {isLoading && <CircularProgress />}
          </PrimaryButton>
        </>
      )}
    </Formik>
  );
};

ResetPasswordModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
