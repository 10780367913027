import { combineReducers } from 'redux';

import team from './team';
import prices from './prices';
import domains from './domains';
import navigation from './navigation';
import compliance from './compliance';
import subscriptions from './subscriptions';
import billingProfile from './billingProfile';

import user from './user';
import trades from './trades';
import tradeHistory from './tradeHistory';
import personalDetails from './personalDetails';

export default combineReducers({
  user,
  trades,
  tradeHistory,
  personalDetails,
  team,
  prices,
  domains,
  compliance,
  navigation,
  subscriptions,
  billingProfile,
});
