import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import pluralize from 'pluralize';
import styled from '@emotion/styled';
import { nanoid } from 'nanoid';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Tab,
  Radio,
  Tabs,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Button,
} from '@material-ui/core';

import Container from '../common/Container';
import TextLink from '../common/TextLink';

import { PrimaryButton, PrimaryMobileButton } from './Controls';
import { WebsiteCard } from './WebsiteCard';
import { ModalPanel } from './ModalPanel';
import { DrawerPanel } from './DrawerPanel';
import {
  cancelSub,
  clearCompliance,
  createDomain,
  getAccess,
  getActiveSubs,
  getAppearance,
  getTrades,
  getMoreTrades,
  getInactiveSubs,
  getPrices,
  getSampleCodeRedesign,
  getSampleCodeSuccess,
  setApiSuccessMessage,
  setToken,
  updateAccess,
  verifyCompliance,
  verifyInstallation,
  setTradeHistorySuccess,
} from '../../store/actions';
import { useQuery } from '../../hooks/useQuery';
import { TabPanel } from '../compliance/TabPanel';
import { ComplianceCard } from './ComplianceCard';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { PayPalButtons } from './PayPalButtons';
import {
  PLAN_EXPIRY_MESSAGE,
  QUERY_PARAM_REDIRECT_TOKEN,
  QUERY_PARAM_REDIRECT_AUDIT,
  QUERY_PARAM_PROMO,
} from '../../common/magicValues';
import { AddDomainLine } from './AddDomainLine';
import { getVerificationMessage } from '../../common/helpers';
// import { buildAuditURL } from '../../common/buildURL';
import LoadingView from '../common/LoadingView';
import { SuccessPillSmall } from '../common/Pill';
import { TypographyH1 } from '../common/styled';
import CustomiseAppearance from './CustomiseAppearance';
import AssetHistory from './AssetHistory';
import SiteSettings from './SiteSettings';
// import { useAnalytics } from '../../providers/AnalyticsProvider';
import Confetti from '../common/Confetti/Confetti';

const Accordion = withStyles({
  root: {
    margin: '0px 32px',
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:last-child': {
      marginBottom: '24px',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0px 32px 42px 32px',
      // margin: 0
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 48,
    padding: 0,
    '&$expanded': {
      minHeight: 48,
      padding: 0,
    },
  },
  content: {
    // margin: "12px 32px",
    // margin: "12px 32px",
    padding: 0,
    margin: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      // margin: "12px 32px",
      padding: 0,
      margin: 0,
      // margin: "12px 0"
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    padding: 0,
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  select: {
    marginRight: '16px',
  },
}));

export const ManageWebsites = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const history = useHistory();
  // const { analytics } = useAnalytics();

  const dispatch = useDispatch();
  const redirectToken = useQuery().get(QUERY_PARAM_REDIRECT_TOKEN);
  const promo = useQuery().get(QUERY_PARAM_PROMO);
  const redirectAudit = useQuery().get(QUERY_PARAM_REDIRECT_AUDIT);

  // useEffect(() => {
  //   analytics.page({
  //     name: 'User Dashboard Landing Page',
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (redirectAudit) {
  //     console.log('In If redirectAudit')
  //     // const url = buildAuditURL(redirectAudit);
  //     // history.push(url);
  //   }
  // }, [history, redirectAudit]);

  const errorMessage = useSelector((state) => state.navigation.error);
  const [activePair, setActivePair] = React.useState(null);
  const [activeTrade, setActiveTrade] = React.useState(null);
  const [labels, setLabels] = React.useState([]);
  const [roiList, setRoiList] = React.useState([]);
  const nowDate = new Date();
  const [initialStart, setInitialStart] = React.useState(nowDate);
  const [initialEnd, setInitialEnd] = React.useState(nowDate);

  const [addWebsiteOpened, setAddWebsiteOpened] = useState(false);
  const [domain, setDomain] = React.useState('');

  const [upgradeSubOpened, setUpgradeSubOpened] = useState(false);
  const [cancelPlanOpened, setCancelPlanOpened] = useState(false);
  const [editSubOpened, setEditSubOpened] = useState(false);
  const [sub, setSub] = React.useState(null);
  const [domainToValidate, setDomainToValidate] = useState('');
  const [validateDomainOpened, setValidateDomainOpened] = useState(false);
  const [verifyIntegrationOpened, setVerifyIntegrationOpened] = useState(false);
  const [domainsToAdd, setDomainsToAdd] = useState(['']);

  // eslint-disable-next-line no-unused-vars
  const [getInstallationCode, setGetInstallationCode] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [activePriceTab, setActivePriceTab] = useState(0);

  const [showDomainProgress, setShowDomainProgress] = useState(false);
  const [installationOpened, setInstallationOpened] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [certificationOpened, setCertificationOpened] = useState(false);
  const [customAppearanceOpened, setCustomAppearanceOpened] = useState(false);

  const onGetErrorsClick = async () => {
    setShowDomainProgress(true);
    dispatch(verifyCompliance(domainToValidate));
  };

  const compliance = useSelector((state) => state.compliance.compliance);
  const validationLoading = useSelector((state) => state.compliance.loading);
  // const config = useSelector(state => state.compliance.config);
  const errors = compliance.filter((c) => c.type === 'error');
  const warnings = compliance.filter((c) => c.type === 'warning');
  const sampleCode = useSelector((state) => state.domains.sampleCode);
  const [code, setCode] = React.useState(sampleCode);

  const handleClosePaymentSuccessModal = () => setPaymentSuccess(false);
  const handleOpenPaymentSuccessModal = () => {
    setEditSubOpened(false);
    setUpgradeSubOpened(false);
    setPaymentSuccess(true);
  };

  // useEffect(() => {
  //   if (redirectToken) {
  //     dispatch(setToken(redirectToken));
  //   } else {
  //     dispatch(getActiveSubs());
  //   }
  // }, [dispatch, redirectToken]);

  // useEffect(() => {
  //   setCode(sampleCode);
  // }, [sampleCode]);

  // const { domains, metaInfo } = useSelector((state) => state.domains);
  // const [domainsCount, setDomainsCount] = useState(domains.length);

  const { trades, metaInfo, loading, loadingMoreTrades } = useSelector(
    (state) => state.trades
  );
  const tradesExist = trades.length > 0;

  const { loading: loadingHistory, history: tradeHistory } = useSelector(
    (state) => state.tradeHistory
  );

  // console.log('tradeHistory', tradeHistory);

  const { subscriptions } = useSelector(
    (state) => state.personalDetails.personalDetails
  );

  const prepareActiveTrade = (trade) => {
    const { pair } = trade.whitelist;
    const updatedTrade = { ...trade };
    updatedTrade.subscription = subscriptions.find(
      (subscription) => subscription.pair === pair
    );
    setActiveTrade(updatedTrade);
  };

  useEffect(() => {
    if (!trades.length) {
      dispatch(getTrades(1));
    }
    // dispatch(getActiveSubs());
    // dispatch(getInactiveSubs());
    dispatch(getPrices());
    // setDomainsCount(domains.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleGetMoreTrades = () => {
    //
    const nextPage = metaInfo.Next;
    if (!nextPage) {
      return;
    }
    dispatch(getMoreTrades(nextPage));
  };

  // useEffect(() => {
  //   setShowDomainProgress(validationLoading);
  // }, [validationLoading]);

  // useEffect(() => {
  //   if (errorMessage) {
  //     setGetInstallationCode(false);
  //     setShowDomainProgress(false);
  //   }
  // }, [errorMessage]);

  const access = useSelector((state) => state.domains.access);
  const [accessToEdit, setaccessToEdit] = useState(access);

  // useEffect(() => {
  //   setaccessToEdit(access);
  // }, [access]);

  const prices = useSelector((state) => state.prices.prices);

  prices.sort(
    (a, b) =>
      Number(a.duration) - Number(b.duration) ||
      a.price - b.price ||
      a.planName.localeCompare(b.planName)
  );

  const [expanded, setExpanded] = useState(prices.length ? prices[0].id : 1);

  // useEffect(() => {
  //   const pr = prices.find((p) => p.promo);
  //   if (pr) {
  //     setExpanded(pr.id);
  //   }
  // }, [prices]);

  // const { activeSubscriptions, inactiveSubscriptions } = useSelector(
  //   (state) => state.subscriptions
  // );

  // const websites = [];
  // const websitesMap = {};

  // domains.forEach((dom) => {
  //   const s =
  //     activeSubscriptions.find(({ website }) => website === dom.name) ??
  //     inactiveSubscriptions.find(({ website }) => website === dom.name);

  //   const website = {
  //     ...dom,
  //     ...s,
  //     isActive: dom.active,
  //   };

  //   website.isEditable = !!website.level;

  //   if (!website.isEditable && website.renew_date) {
  //     const date = moment(website.renew_date);
  //     if (date.isAfter(moment())) {
  //       website.isEditable = true;
  //     }
  //   }

  //   websites.push(website);
  //   if (!websitesMap[website.name]) {
  //     websitesMap[website.name] = website;
  //   }
  // });

  // const activeDomainsCount = domains.filter((web) => web.active).length;

  const openEditSub = (s) => {
    if (s.isEditable) {
      setEditSubOpened(true);
    } else {
      setUpgradeSubOpened(true);
    }
    // analytics.track('Website Settings Option Clicked', {
    //   option: 'upgrade',
    //   url: s.name,
    //   id: websitesMap[s.name].id,
    //   trigger: 'click',
    // });
    setSub(s);
  };

  // useEffect(() => {
  //   if (domain !== '' && domainsCount !== domains.length) {
  //     const domainToTrack = domains.find((dom) => dom.name === domain)?.id;
  //     if (domainToTrack) {
  //       // analytics.track('Website Settings Option Clicked', {
  //       //   option: 'installation',
  //       //   url: history.location,
  //       //   id: domainToTrack,
  //       //   trigger: 'website created',
  //       // });
  //     }
  //     setDomainsCount(domains.length);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [domain, domains]);

  const openCancelSub = () => {
    setEditSubOpened(false);
    setCancelPlanOpened(true);
  };

  const openUpgradeSub = () => {
    setEditSubOpened(false);
    setUpgradeSubOpened(true);
  };

  const openCertification = (domainName) => {
    setDomain(domainName);
    dispatch(getAccess(domainName));
    setCertificationOpened(true);
    // analytics.track('Website Settings Option Clicked', {
    //   option: 'certificate',
    //   url: domainName,
    //   id: websitesMap[domainName].id,
    // });
  };

  const closeCertification = () => {
    setCertificationOpened(false);
  };

  const saveCertification = () => {
    dispatch(updateAccess(domain, accessToEdit));
    setDomain('');
    setCertificationOpened(false);
  };

  const closeVerification = () => {
    setVerifyIntegrationOpened(false);
    setSub(null);
  };

  const cnSub = (website) => {
    dispatch(cancelSub({ website }));
    setCancelPlanOpened(false);
  };

  const closeCancelSub = () => {
    setCancelPlanOpened(false);
  };

  const openTradeHistory = (trade) => {
    dispatch(setTradeHistorySuccess(trade));
    setActivePair(trade.whitelist.pair);
    prepareActiveTrade(trade);
    setInstallationOpened(true);
  };

  useEffect(() => {
    if (activeTrade) {
      const { pair, price } = activeTrade.whitelist;
      const lastTrade = activeTrade.trade_list.slice(-1)[0];
      let uPNL = 0.0;
      if (lastTrade.is_active) {
        uPNL = lastTrade.is_short
          ? (lastTrade.open_rate - price) / lastTrade.open_rate
          : (price - lastTrade.open_rate) / lastTrade.open_rate;
      }
      // Compile trade data
      // console.log('tradeHistory??', tradeHistory);
      // console.log('pair', pair);
      // console.log('tradeHistory[pair][0]', tradeHistory[pair]);
      // console.log(
      //   'tradeHistory[pair].slice(-1)[0]',
      //   tradeHistory[pair].slice(-1)[0]
      // );
      const newLabels = [];
      const newRoiList = [];
      const start = tradeHistory[pair][0].date;
      let end = tradeHistory[pair].slice(-1)[0].date;
      //
      tradeHistory[pair].forEach((currentHistory, i) => {
        let formattedDate = moment(currentHistory.date).format('DD/MM');
        // Include unrealized PNL
        if (
          currentHistory.roi === 0 &&
          tradeHistory[activePair].length === i + 1
        ) {
          end = new Date();
          formattedDate = moment(end).format('DD/MM');
          newLabels.push(formattedDate);
          newRoiList.push(uPNL);
        } else {
          newLabels.push(formattedDate);
          newRoiList.push(currentHistory.roi);
        }
      });

      setRoiList(newRoiList);
      setLabels(newLabels);
      setInitialStart(start);
      setInitialEnd(end);
    }
    // eslint-disable-next-line
  }, [tradeHistory]);

  const trackNewWebsite = (newDomain) => {
    // analytics.track('New Website Created', {
    //   domain_record_id: newDomain.id,
    //   url: newDomain.name,
    //   subscription_id: newDomain.subscription_id,
    // });
    // analytics.track('Subscription Created', {
    //   planId: newDomain.subscription_id,
    //   planPrice: 0,
    //   domain: newDomain.name,
    //   freeTrialPeriod: newDomain.expiration,
    // });
    // Update subscription group
    // const { isSpecialDomain, testAccount } = userPersonalDetails || {};
    // analytics.group(newDomain.subscription_id, {
    //   groupType: 'subscription',
    //   subscription_id: newDomain.subscription_id,
    //   free_trial_period: newDomain.expiration,
    //   user_id: user.user_id,
    //   is_special_domain: isSpecialDomain,
    //   is_test_user: testAccount,
    // });
  };

  const closeInstallation = () => {
    setInstallationOpened(false);
    dispatch(getSampleCodeSuccess({ value: { code: '' } }));
    dispatch(setApiSuccessMessage(''));
  };

  const verifyInstallationClick = (s) => {
    closeInstallation();
    setVerifyIntegrationOpened(true);
    setSub(s);
    // dispatch(verifyInstallation(website));
  };

  const openCustomAppearance = (domainName) => {
    setDomain(domainName);
    dispatch(getAppearance(domainName));
    setCustomAppearanceOpened(true);
    // analytics.track('Website Settings Option Clicked', {
    //   option: 'custom-appearance',
    //   url: domainName,
    //   id: websitesMap[domainName].id,
    //   trigger: 'click',
    // });
  };

  const closeCustomAppearance = () => {
    setCustomAppearanceOpened(false);
  };

  const openSettings = (trade) => {
    console.log('trade', trade);
    // setDomain(websiteName);
    // dispatch(getAppearance(websiteName));
    setActivePair(trade.whitelist.pair);
    prepareActiveTrade(trade);
    setSettingsOpened(true);
  };

  const closeSettings = () => {
    setSettingsOpened(false);
  };

  const openAddWebsite = () => {
    setAddWebsiteOpened(true);
    // analytics.track('New Website Form Opened');
  };

  const closeEditSub = () => {
    setEditSubOpened(false);
    setUpgradeSubOpened(false);
    setSub(null);
  };

  const closeAddWebsite = () => {
    setAddWebsiteOpened(false);
    setGetInstallationCode(false);
    // analytics.track('New Website Form Closed');
    setDomainsToAdd(['']);
    setShowDomainProgress(false);
    dispatch(getSampleCodeSuccess({ value: { code: '' } }));
    setDomain('');
  };

  const extractHostname = (url) => {
    let hostname;
    if (url.indexOf('//') > -1) {
      // eslint-disable-next-line prefer-destructuring
      hostname = url.split('/')[2];
    } else {
      // eslint-disable-next-line prefer-destructuring
      hostname = url.split('/')[0];
    }

    // eslint-disable-next-line prefer-destructuring
    hostname = hostname.split('?')[0];

    return hostname.replace('www.', '');
  };

  const addDomain = (d, i) => {
    const dom = [...domainsToAdd];
    dom[i] = d;
    if (i === domainsToAdd.length - 1 && d.length >= 3) {
      dom.push('');
    }
    setDomainsToAdd(dom);
  };

  const addDomains = () => {
    let lastDomain = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const dom of domainsToAdd) {
      if (dom) {
        const newDomain = extractHostname(dom.toLocaleLowerCase());

        dispatch(createDomain(newDomain, trackNewWebsite));
        setGetInstallationCode(true);
        setShowDomainProgress(true);

        // eslint-disable-next-line no-undef
        dataLayer.push({
          event: 'freeTrial',
          domain: newDomain,
        });

        lastDomain = dom;
      }
      setDomain(lastDomain);
    }
    setDomainsToAdd(['']);
    setAddWebsiteOpened(false);
    setInstallationOpened(true);
  };

  // const openValidateDomain = val => {
  //   setDomainToValidate(val);
  //   setValidateDomainOpened(true);
  // };

  const closeValidateDomain = () => {
    dispatch(clearCompliance());
    setShowDomainProgress(false);
    setValidateDomainOpened(false);
  };

  const sendInstructionsClick = () => {
    const instructions = `Hello,

Please paste the code below right before the end of your sites body.
${code}

Or use one of the integration guides to implement Equally AI
https://equally.ai/integrations

Thank you`;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(instructions);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = instructions;
      textArea.style.widgetConfigSitePosition = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      dispatch(setApiSuccessMessage('Instructions copied to clipboard!'));
      document.execCommand('copy');
      textArea.remove();
    }

    dispatch(setApiSuccessMessage('Instructions copied to clipboard!'));
  };

  const copyCodeToClipboard = (domainName) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(code);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = code;
      textArea.style.widgetConfigSitePosition = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      dispatch(
        setApiSuccessMessage(
          `${domainName} code copied! Once installed, the widget will appear. Expect our warm emails as we work on the accessibility and prepare your statement.`,
          false
        )
      );
      document.execCommand('copy');
      textArea.remove();
    }

    dispatch(
      setApiSuccessMessage(
        `${domainName} code copied! Once installed, the widget will appear. Expect our warm emails as we work on the accessibility and prepare your statement.`,
        false
      )
    );
    if (domainName) {
      // analytics.track('Installation Pop Up Option Clicked', {
      //   option: 'copy-code-to-clipboard',
      //   url: domainName,
      //   id: websitesMap[domainName].id,
      // });
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (panel !== expanded) {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const pagesCountPerPlan = (planName) => {
    switch (planName) {
      case 'Small':
        return 'Upto 1,000';
      case 'Medium':
        return 'Upto 10,000';
      case 'Large':
        return 'Upto 100,000';
      case 'Unlimited':
        return 'Unlimited';
      default:
        return 1000;
    }
  };

  const getPlanNumbersWithDiscount = (s, p) => {
    const planPrice = Number(p.price);
    const duration = Number(p.duration);
    const divider = duration / 12;
    const finalPrice = Math.floor(planPrice / divider);
    const durations = p.durations ? p.durations.length : 0;

    const subTotal = durations ? p.durations[0].price : planPrice;
    const discount = Math.floor((planPrice / duration) * 6);
    const total = subTotal - discount;

    return {
      planPrice,
      duration,
      finalPrice,
      subTotal,
      discount,
      total,
      durations,
    };
  };

  const getPlanNumbersWithoutDiscount = (s, p) => {
    const planPrice = Number(p.price);
    const duration = Number(p.duration) || 1;
    const divider = duration / 12;
    const finalPrice =
      divider && duration >= 12 ? Math.floor(planPrice / divider) : planPrice;
    const durations = p.durations ? p.durations.length : 0;

    const subTotal = durations ? p.durations[0].price : planPrice;
    const discount = 0;
    const total = subTotal - discount;
    return {
      planPrice,
      duration,
      finalPrice,
      subTotal,
      discount,
      total,
      durations,
    };
  };

  const getSubTotal = (p, subTotal, durations) => {
    if (!durations) {
      return `$${subTotal}`;
    }

    return p.durations.map((d, i) => {
      if (i === 0) {
        if (!d.price) {
          return (
            <div key={nanoid()}>
              {`Free for ${pluralize(
                d.durationUnit.toLowerCase(),
                d.durationCount,
                true
              )}`}
            </div>
          );
        }
        return (
          <div key={nanoid()}>
            {`$${d.price.toFixed(2)} for ${pluralize(
              d.durationUnit.toLowerCase(),
              d.durationCount,
              true
            )}`}
          </div>
        );
      }
      if (!d.price) {
        return (
          <div key={nanoid()}>
            {`Then free for ${pluralize(
              d.durationUnit.toLowerCase(),
              d.durationCount,
              true
            )}`}
          </div>
        );
      }
      return (
        <div key={nanoid()}>
          {`Then $${d.price.toFixed(2)} for ${pluralize(
            d.durationUnit.toLowerCase(),
            d.durationCount,
            true
          )}`}
        </div>
      );
    });
  };

  const getPriceDurationDate = (price) => {
    const res = moment();
    // durations.forEach((d) =>
    //   res.add(d.durationCount, d.durationUnit.toLowerCase())
    //   // moment(res).add(1, 'M')
    // );
    res.add(1, price.durationUnit);
    return res.format('Do MMMM YYYY');
  };

  const priceAccordion = (s, p, useDiscount = false) => {
    // console.log({ s, p });
    const {
      planPrice,
      finalPrice,
      subTotal,
      durations,
      // discount,
      total,
    } = useDiscount
      ? getPlanNumbersWithDiscount(s, p)
      : getPlanNumbersWithoutDiscount(s, p);

    const planName = p.planName.split(' ')[0];

    return (
      <Accordion
        key={p.id}
        square
        expanded={expanded === p.id}
        onChange={handleChange(p.id)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className="modal-pricing-header">
            <FormControlLabel
              style={{ marginRight: 0 }}
              control={<Radio color="default" checked={expanded === p.id} />}
              label={planName}
            />
            <div className="modal-pricing-1000-pages">
              {`(${pagesCountPerPlan(planName)} pages)`}
            </div>
          </div>
          <div className="modal-pricing-price">
            {`$${finalPrice.toFixed(2)}/${p.durationUnit.toLowerCase()}`}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="modal-pricing-details-line">
            <div className="modal-pricing-details-line-subtotal">Subtotal</div>
            <div className="modal-pricing-details-line-subtotal-price">
              {getSubTotal(p, subTotal, durations)}
            </div>
          </div>
          <div />
          <div className="modal-pricing-details-line-total">
            <div className="modal-pricing-details-line-total">Total to pay</div>
            <div className="modal-pricing-details-line-total-price">
              {`$${total}`}
            </div>
          </div>
          {sub && (
            <PayPalButtons
              closeModal={closeEditSub}
              pair={s.whitelist.pair}
              planPrice={planPrice}
              plan_id={p.priceID}
              onSuccess={handleOpenPaymentSuccessModal}
              // onSubmit={submitHandler}
            />
          )}
          {/* <div>
            Your plan will expire on the &nbsp;
            <strong>{`${getPriceDurationDate(p)}.`}</strong>
          </div> */}
        </AccordionDetails>
      </Accordion>
    );
  };

  const cancelSubFields = () => (
    <div className="cancel-buttons">
      <PrimaryButton
        color="primary"
        variant="outlined"
        onClick={() => cnSub(sub.name)}
        style={{ marginRight: '16px' }}
      >
        <div className="edit-buttons-cancel-text">Yes</div>
      </PrimaryButton>
      <PrimaryButton
        color="primary"
        variant="contained"
        onClick={closeCancelSub}
      >
        No, thank you
      </PrimaryButton>
    </div>
  );

  const integrationValidationFields = (s) => (
    <>
      <div>
        {validationLoading && <CircularProgress />}
        {!validationLoading && <div>{getVerificationMessage(s)}</div>}
      </div>
    </>
  );

  const PayPal = () => (
    <div className="invoices-payment-method-container">
      <img className="pay-pal-logo" alt="visa" src="pp.png" />
      <div className="edit-line-item-value">PayPal</div>
    </div>
  );

  const editSubFields = (s) => (
    <>
      <TextField
        label="Website URL"
        disabled
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        value={s.name}
        className={classes.input}
      />

      <div>
        <div className="edit-level-container">
          <div className="edit-level-container-level">
            {s.level ? s.level.split(' ')[0] : ''}
          </div>
          <div className="edit-level-container-pages">
            {`${pagesCountPerPlan(s.level ? s.level.split(' ')[0] : '')} pages`}
          </div>
          <div>
            <PrimaryButton
              color="primary"
              variant="contained"
              onClick={openUpgradeSub}
              style={{ width: '109px' }}
            >
              Upgrade
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="edit-line-item">
        <div className="edit-sub-line-item edit-line-item-label">
          <ViewStatementLink
            to={`/statement?domain=${encodeURIComponent(s.name)}`}
            target="_blank"
            underline="none"
          >
            View accessibility statement
          </ViewStatementLink>
        </div>
      </div>
      <div className="edit-line-item">
        <div className="edit-sub-line-item edit-line-item-label">Status</div>
        <div className="edit-sub-line-item">
          <div
            className={`website-card-tag ${
              s.isActive
                ? 'website-card-tag-active'
                : 'website-card-tag-inactive'
            }`}
          >
            {s.isActive ? 'Active' : 'Inactive'}
          </div>
        </div>
      </div>
      <div className="edit-line-item">
        <div className="edit-sub-line-item edit-line-item-label">Plan</div>
        <div className="edit-sub-line-item">
          <div className="website-card-tag website-card-tag-level">
            {s.level ? s.level.split(' ')[0] : ''}
          </div>
        </div>
      </div>
      <div className="edit-line-item">
        <div className="edit-sub-line-item edit-line-item-label">
          Your plan expires on
        </div>
        <div className="edit-sub-line-item edit-line-item-value">
          {moment(s.expiration).format('Do MMMM YYYY')}
        </div>
      </div>
      <div className="edit-line-item">
        <div className="edit-sub-line-item edit-line-item-label">
          Next capture
        </div>
        <div className="edit-sub-line-item edit-line-item-value">
          {moment(s.expiration).format('Do MMMM YYYY')}
        </div>
      </div>
      <div className="edit-line-item">
        <div className="edit-sub-line-item edit-line-item-label">Paid</div>
        <div className="edit-sub-line-item edit-line-item-value">Anually</div>
      </div>
      {/* <div className="edit-line-item"> */}
      {/*  <div className="edit-sub-line-item edit-line-item-label"> */}
      {/*    Auto-renewal */}
      {/*  </div> */}
      {/*  <div className="edit-sub-line-item"> */}
      {/*    <div className="edit-sub-line-item-renewal"> */}
      {/*      <div className="edit-sub-line-item-renewal-active">ON</div> */}
      {/*      <div className="edit-sub-line-item-renewal-inactive">OFF</div> */}
      {/*    </div> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </div> */}
      <div className="edit-line-item">
        <div className="edit-sub-line-item edit-line-item-label">
          Payment method
        </div>
        <div className="edit-sub-line-item">{PayPal()}</div>
      </div>
      <div className="edit-line-item edit-line-item-last">
        <div className="edit-sub-line-item edit-line-item-label">Price</div>
        <div className="edit-sub-line-item edit-line-item-value">
          {`$${s.payment_amount}`}
        </div>
      </div>
      <div className="edit-buttons">
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={openUpgradeSub}
          style={{ marginRight: '16px' }}
        >
          Change Plan
        </PrimaryButton>
        <PrimaryButton
          color="primary"
          variant="outlined"
          onClick={openCancelSub}
        >
          <div className="edit-buttons-cancel-text">Cancel Plan</div>
        </PrimaryButton>
      </div>
    </>
  );

  const upgradeSubFields = () => {
    // console.log(prices);
    const oneYearPrices = prices.filter(
      (p) => (Number(p.duration) === 12 && p.durationUnit === 'YEAR') || p.promo
    );
    oneYearPrices.sort((a, b) => {
      if (a.promo) {
        return -1;
      }
      if (b.promo) {
        return 1;
      }
      return 0;
    });

    const twoYearPrices = prices.filter(
      (p) => Number(p.duration) === 24 && p.durationUnit === 'YEAR'
    );
    const oneMonthPrices = prices.filter(
      (p) => Number(p.duration) === 0 && p.durationUnit === 'MONTH'
    );

    const needTabs =
      oneYearPrices.length && (twoYearPrices.length || oneMonthPrices.length);

    console.log({
      oneYearPrices,
      twoYearPrices,
      oneMonthPrices,
      needTabs,
    });
    return (
      <>
        {/* <FormControlLabel
          style={{ marginTop: '16px' }}
          control={
            <Checkbox checked={sub && sub.isActive} color="primary" disabled />
          }
          label="Active"
        /> */}
        <div>
          <h2 className="modal-panel-second-title">Choose a plan</h2>
          <div className="modal-panel-second-sub-title">
            All plans include 30 days free trial!
          </div>
        </div>
        {!prices.length && <CircularProgress />}
        {!!prices.length && (
          <div className="modal-compliance-tabs">
            {needTabs ? (
              <>
                <div className="modal-compliance-tabs-header">
                  <Tabs
                    indicatorColor="primary"
                    value={activePriceTab}
                    onChange={(_, value) => setActivePriceTab(value)}
                  >
                    <Tab
                      style={{ width: '50%', textTransform: 'none' }}
                      label={
                        <span className="modal-compliance-tab-content">
                          Annual
                        </span>
                      }
                    />
                    <Tab
                      style={{ width: '50%', textTransform: 'none' }}
                      label={
                        <span className="modal-compliance-tab-content">
                          Monthly
                        </span>
                      }
                    />
                  </Tabs>
                </div>

                <TabPanel value={activePriceTab} index={0}>
                  {oneYearPrices.map((p) => priceAccordion(sub, p))}
                </TabPanel>
                <TabPanel
                  value={activePriceTab}
                  index={1}
                  style={{ width: '50%' }}
                >
                  {oneMonthPrices.map((p) => priceAccordion(sub, p))}
                </TabPanel>
              </>
            ) : (
              <>{oneYearPrices.map((p) => priceAccordion(sub, p))}</>
            )}
          </div>
        )}
      </>
    );
  };

  const addDomainFields = () => (
    <>
      <div className="modal-panel-sub-title">
        <span>Every website added gets a </span>
        <span>
          <b>14-day free trial</b>
        </span>
        <span>
          ! You can skip the trial and purchase a license directly in the next
          step.
        </span>
      </div>
      {domainsToAdd.map((d, i) => (
        <AddDomainLine index={i} domainChanged={addDomain} />
      ))}
      <div className="add-website-fields">
        <PrimaryButton color="primary" variant="contained" onClick={addDomains}>
          Start Free Trial
        </PrimaryButton>
      </div>
    </>
  );

  const validateWebsiteFields = () => (
    <>
      <div className="add-website-fields">
        <TextField
          label="Website URL"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={domainToValidate}
          onChange={(e) => setDomainToValidate(e.target.value)}
          className={classes.input}
        />
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={onGetErrorsClick}
          style={{ width: '77px' }}
        >
          Start Free Trial
        </PrimaryButton>
      </div>
      {!compliance.length && showDomainProgress && (
        <div className="add-website-progress">
          <CircularProgress size={100} />
        </div>
      )}
      {!!compliance.length && (
        <div className="modal-compliance-tabs">
          <div className="modal-compliance-tabs-header">
            <Tabs
              indicatorColor="primary"
              value={activeTab}
              onChange={(_, value) => setActiveTab(value)}
              aria-label="simple tabs example"
            >
              <Tab
                style={{ width: '50%', textTransform: 'none' }}
                label={
                  <span className="modal-compliance-tab-content">
                    {`Errors (${errors.length})`}
                  </span>
                }
              />
              <Tab
                style={{ width: '50%', textTransform: 'none' }}
                label={
                  <span className="modal-compliance-tab-content">
                    {`Warnings (${warnings.length})`}
                  </span>
                }
              />
            </Tabs>
          </div>

          <TabPanel value={activeTab} index={0}>
            {errors.map((el, index) => (
              <ComplianceCard
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                code={el.code}
                message={el.message}
                context={el.context}
                type="error"
              />
            ))}
            {!!compliance.length && !errors.length && (
              <div className="modal-compliance-item-empty">No errors found</div>
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1} style={{ width: '50%' }}>
            {warnings.map((el, index) => (
              <ComplianceCard
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                code={el.code}
                message={el.message}
                context={el.context}
                type="warning"
              />
            ))}
            {!!compliance.length && !warnings.length && (
              <div className="modal-compliance-item-empty">
                No warnings found
              </div>
            )}
          </TabPanel>
        </div>
      )}
    </>
  );

  const handleAccessName = (e) => {
    setaccessToEdit({
      ...accessToEdit,
      name: e.target.value,
    });
  };
  const handleAccessEmail = (e) => {
    setaccessToEdit({
      ...accessToEdit,
      email: e.target.value,
    });
  };

  const contactInfoFields = () => (
    <>
      <div className="modal-panel-sub-title">
        {`Note: If you are an agency purchasing for a client, you should enter
            your client's contact information.`}
      </div>
      {isMobile && (
        <>
          <div className="add-website-fields">
            <TextField
              label="Website’s owner name"
              variant="outlined"
              className={classes.input}
              value={accessToEdit.name}
              onChange={handleAccessName}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="add-website-fields">
            <TextField
              label="Website’s owner email"
              variant="outlined"
              value={accessToEdit.email}
              onChange={handleAccessEmail}
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </>
      )}
      {!isMobile && (
        <div className="add-website-fields">
          <TextField
            label="Website’s owner name"
            variant="outlined"
            className={classes.input}
            value={accessToEdit.name}
            onChange={handleAccessName}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Website’s owner email"
            variant="outlined"
            value={accessToEdit.email}
            onChange={handleAccessEmail}
            className={classes.input}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )}
      <div className="add-website-fields">
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={saveCertification}
          style={{ width: '83px' }}
        >
          Save
        </PrimaryButton>
      </div>
    </>
  );

  let subExpired = false;

  if (sub) {
    if (!sub.isActive) {
      subExpired = true;
    } else if (sub.renew_date) {
      const subRenewTS = new Date(sub.renew_date).getTime();
      if (subRenewTS < Date.now()) {
        subExpired = true;
      }
    }
  }

  return (
    <Container>
      <div className="header-manage-websites">
        <div className="header-manage-websites-text">
          <HeadingTypography>
            Screened Assets{' '}
            {/* {activeDomainsCount > 2 && (
              <SuccessPillSmall>{activeDomainsCount} ACTIVE</SuccessPillSmall>
            )} */}
          </HeadingTypography>
        </div>
        <LoadingView open={loading} />
        {/* {tradesExist && !loading && !isMobile && (
          <PrimaryButton
            color="primary"
            variant="contained"
            onClick={openAddWebsite}
          >
            + Add New Website
          </PrimaryButton>
        )} */}
        {/* {tradesExist && !loading && isMobile && (
          <PrimaryMobileButton
            color="primary"
            variant="contained"
            onClick={openAddWebsite}
          >
            + Add New Website
          </PrimaryMobileButton>
        )} */}
      </div>
      {tradesExist && !loading && (
        <>
          <div className="websites-list">
            {trades.map((ws, i) => (
              <WebsiteCard
                trade={ws}
                // eslint-disable-next-line react/no-array-index-key
                key={`ws-${i}`}
                openCustomAppearance={openCustomAppearance}
                openTradeHistory={openTradeHistory}
                openCertification={openCertification}
                openEditSub={openEditSub}
                openVerifyInstallation={verifyInstallationClick}
                openSettings={openSettings}
              />
            ))}
          </div>
          {/* <MoreDomains>
            {loadingMoreTrades ? (
              <CircularProgress />
            ) : (
              metaInfo.Next && (
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleGetMoreTrades}
                >
                  More
                </Button>
              )
            )}
          </MoreDomains> */}
        </>
      )}
      {!isMobile && (
        <>
          <ModalPanel
            title="Add your website(s)"
            open={addWebsiteOpened}
            handleClose={closeAddWebsite}
            applySub
          >
            {addDomainFields()}
          </ModalPanel>
          <ModalPanel
            title="Subscribe to a plan"
            open={upgradeSubOpened}
            handleClose={closeEditSub}
            notification={subExpired ? PLAN_EXPIRY_MESSAGE : ''}
          >
            {upgradeSubFields()}
          </ModalPanel>
          <ModalPanel
            title="Change subscription plan"
            open={editSubOpened}
            handleClose={closeEditSub}
          >
            {editSubFields(sub || { level: '' })}
          </ModalPanel>
          <ModalPanel
            title="Asset Configurations"
            open={settingsOpened}
            handleClose={closeSettings}
          >
            <SiteSettings
              closeSettings={closeSettings}
              trade={activeTrade}
              // domain={domain}
              // setDomain={setDomain}
            />
          </ModalPanel>
          <ModalPanel
            title={`${activePair} Trade History`}
            subTitle=""
            open={installationOpened}
            handleClose={closeInstallation}
          >
            <AssetHistory
              code={code}
              sendInstructionsClick={sendInstructionsClick}
              verifyInstallationClick={verifyInstallationClick}
              copyCodeToClipboard={copyCodeToClipboard}
              domain={domain}
              loadingHistory={loadingHistory}
              roiList={roiList}
              labels={labels}
              initialStart={initialStart}
              initialEnd={initialEnd}
            />
          </ModalPanel>
          {/* <ModalPanel
            title="Validate website"
            subTitle="Validate website for AODA, ADA, Section 508, WCAG 2.1 & Compliance"
            open={validateDomainOpened}
            handleClose={closeValidateDomain}
          >
            {validateWebsiteFields()}
          </ModalPanel> */}
          {/* <ModalPanel
            title="Customize appearance"
            open={customAppearanceOpened}
            handleClose={closeCustomAppearance}
          >
            <CustomiseAppearance
              domain={domain}
              setDomain={setDomain}
              setCustomAppearanceOpened={setCustomAppearanceOpened}
            />
          </ModalPanel> */}
          {/* <ModalPanel
            title="Website owner’s contact info"
            subTitle="This contact info will be used in the Accessibility Statement, to comply with the WCAG 2.1, and therefore with the ADA and Section 508. equally.ai does not use personal information and abides by data privacy laws."
            open={certificationOpened}
            handleClose={closeCertification}
          >
            {contactInfoFields()}
          </ModalPanel> */}
          {/* <ModalPanel
            title="Verify integration"
            subTitle=""
            open={verifyIntegrationOpened}
            handleClose={closeVerification}
          >
            {integrationValidationFields(sub)}
          </ModalPanel> */}
          <ModalPanel
            title="Are you sure you want to cancel?"
            subTitle=""
            open={cancelPlanOpened}
            handleClose={closeCancelSub}
          >
            {cancelSubFields()}
          </ModalPanel>
        </>
      )}
      {isMobile && (
        <>
          {/* <DrawerPanel
            anchor="bottom"
            title="Add a Website"
            open={addWebsiteOpened}
            handleClose={closeAddWebsite}
            applySub
          >
            {addDomainFields()}
          </DrawerPanel> */}
          <DrawerPanel
            anchor="bottom"
            title="Upgrade website plan"
            open={upgradeSubOpened}
            handleClose={closeEditSub}
            notification={subExpired ? PLAN_EXPIRY_MESSAGE : ''}
          >
            {upgradeSubFields()}
          </DrawerPanel>
          <DrawerPanel
            anchor="bottom"
            title="Website plan"
            open={editSubOpened}
            handleClose={closeEditSub}
          >
            {editSubFields(sub || { level: '' })}
          </DrawerPanel>
          <DrawerPanel
            anchor="bottom"
            title="Validate website"
            subTitle="Validate website for AODA, ADA, Section 508, WCAG 2.1 & Compliance"
            open={validateDomainOpened}
            handleClose={closeValidateDomain}
          >
            {validateWebsiteFields()}
          </DrawerPanel>
          <DrawerPanel
            anchor="bottom"
            title="Trade Hisotory"
            subTitle="Paste the code below right before the end of your sites body."
            open={installationOpened}
            handleClose={closeInstallation}
          >
            <AssetHistory
              code={code}
              sendInstructionsClick={sendInstructionsClick}
              verifyInstallationClick={verifyInstallationClick}
              copyCodeToClipboard={copyCodeToClipboard}
              domain={domain}
              loadingHistory={loadingHistory}
              roiList={roiList}
              labels={labels}
              initialStart={initialStart}
              initialEnd={initialEnd}
            />
          </DrawerPanel>
          <DrawerPanel
            anchor="bottom"
            title="Settings"
            open={settingsOpened}
            handleClose={closeSettings}
          >
            <SiteSettings
              closeSettings={closeSettings}
              trade={activeTrade}
              // domain={domain}
              // setDomain={setDomain}
            />
          </DrawerPanel>
          <DrawerPanel
            anchor="bottom"
            title="Your website is being setup"
            subTitle=""
            open={verifyIntegrationOpened}
            handleClose={closeVerification}
          >
            {integrationValidationFields()}
          </DrawerPanel>
          <DrawerPanel
            anchor="bottom"
            title="Are you sure you want to cancel?"
            subTitle=""
            open={cancelPlanOpened}
            handleClose={closeCancelSub}
          >
            {cancelSubFields()}
          </DrawerPanel>
        </>
      )}
      {/* {paymentSuccess && (
        <Confetti
          showConfetti={paymentSuccess}
          hideConfetti={handleClosePaymentSuccessModal}
        />
      )} */}
    </Container>
  );
};

/* Styled Components */

const HeadingTypography = styled(TypographyH1)`
  font-size: 32px;
  margin-right: 5px;
  font-weight: 500;
`;

const ViewStatementLink = styled(TextLink)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  color: rgba(0, 10, 20, 0.85);
`;

const MoreDomains = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
