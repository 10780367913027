import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';

import ThemeProvider from './providers/ThemeProvider';
import NotificationProvider from './providers/NotificationProvider';
// import { AnalyticsProvider } from './providers/AnalyticsProvider';
import Main from './components/Main';

const App = ({ store }) => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        {/* <AnalyticsProvider> */}
        <NotificationProvider>
          <Router>
            <Main />
          </Router>
        </NotificationProvider>
        {/* </AnalyticsProvider> */}
      </ThemeProvider>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
