import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import CheckmarkIcon from './CheckmarkIcon';

const Checkmark = ({ children, light, icon, align, ...props }) => {
  return (
    <CheckmarkContainer align={align} {...props}>
      {icon && icon}
      {!icon && (light ? <CheckmarkIconLight /> : <CheckmarkIconDark />)}
      <Box ml="16px" />
      <span>{children}</span>
    </CheckmarkContainer>
  );
};

Checkmark.propTypes = {
  children: PropTypes.any.isRequired,
  light: PropTypes.bool,
  icon: PropTypes.any,
  align: PropTypes.oneOf(['top', 'bottom', 'center']),
};

Checkmark.defaultProps = {
  light: false,
  icon: null,
  align: 'center',
};

export default Checkmark;

/** Styled Components */

const handleAlign = (align) => {
  switch (align) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    default:
      return 'center';
  }
};

const CheckmarkContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'align',
})`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => handleAlign(align)};

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`;

const CheckmarkIconLight = styled(CheckmarkIcon)`
  border-color: rgba(255, 255, 255, 0.24);
`;

const CheckmarkIconDark = styled(CheckmarkIcon)`
  border-color: #cbd2d9;
`;
