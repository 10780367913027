import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';

import { AbsoluteCenter } from '../styled';

import ModalBase from '../ModalBase';

const PresentationModal = ({ children, open }) => {
  return (
    <ModalBase open={open}>
      <Global
        styles={css`
          body #root {
            filter: blur(5px);
          }
        `}
      />
      <PresentationBG>
        <PresentationContent>{children}</PresentationContent>
      </PresentationBG>
    </ModalBase>
  );
};

PresentationModal.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
};

PresentationModal.defaultProps = {
  children: null,
  open: false,
};

export default PresentationModal;

/** Styled Components */

const PresentationBG = styled.div`
  position: fixed;
  z-index: 1300;
  inset: 0px;

  background: rgba(255, 255, 255, 0.9);
`;

const PresentationContent = styled(AbsoluteCenter)`
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100vh;

  top: min(396px, 50%);
`;
