import React from 'react';
import PropTypes from 'prop-types';
import { Container as MuiContainer } from '@material-ui/core';
import styled from '@emotion/styled';

const Container = ({ children, ...props }) => {
  return (
    <OutterContainer component="section" maxWidth={false} {...props}>
      <InnerContainer>{children}</InnerContainer>
    </OutterContainer>
  );
};

export default Container;

Container.propTypes = {
  children: PropTypes.any.isRequired,
};

/** Styled Components */

const InnerContainer = styled(MuiContainer)`
  padding: 0;
`;

const OutterContainer = styled(MuiContainer)`
  overflow: hidden;

  padding-left: 24px;
  padding-right: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 80px;
    padding-right: 80px;
  }
`;
