import React, { useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Box,
  MenuList,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from '@emotion/styled';

// import '../redesign/Styles/trader.css';
// const assetArry = ['BTC', 'ETH', 'BNB'];

export default function SearchField({ symbol, setSymbol, isDesktop }) {
  const [open, setOpen] = useState(isDesktop);

  const handleValueChange = (value) => {
    setSymbol(value);
  };

  const handleClose = () => {
    setOpen(true);
    // if (!isDesktop) {
    //   setOpen(false);
    // }
  };

  return (
    <AutocompleteBox
      freeSolo
      disablePortal
      value={symbol}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      onChange={(event, value) => handleValueChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          variant="outlined"
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(handleClose)}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      options={['BTC', 'ETH', 'BNB']}
      PaperComponent={({ children }) => {
        return (
          <PaperRefined>
            <Box mt={5}>
              <GridHeader container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1">Pair</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">Price</Typography>
                </Grid>
              </GridHeader>
            </Box>
            <Grid container>
              <UnOrderedList>{children}</UnOrderedList>
            </Grid>
          </PaperRefined>
        );
      }}
      renderOption={(option, state) => (
        <Grid data-symbol={option} item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">{option}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">---</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    />
  );
}

const AutocompleteBox = styled(Autocomplete)`
  position: relative !important;
`;

const PaperRefined = styled(Paper)`
  box-shadow: none;
`;

const GridHeader = styled(Grid)`
  padding: 0 16px;
`;

const UnOrderedList = styled(MenuList)`
  width: 100%;
`;

SearchField.propTypes = {
  setSymbol: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};
