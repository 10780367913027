import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  Grid,
  ButtonGroup,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from './Controls';

import { updateSettings } from '../../store/actions';
import { DropButton } from '../common/DropdownButton';
// import Switch from '../common/SwitchToggle/SwitchToggle';
// import { LANGUAGES } from '../../common/languages';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginTop: '10px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& fieldset': {
      top: '-4px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  selectIconContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      fontSize: '12px',
      marginBottom: '5px',
    },
  },
  iconContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: '100%',
  },
  switchGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '12px',
    border: '2px solid #E4E7EB',
    padding: '8px 16px',
    margin: '10px 0',
    maxHeight: 'fit-content',

    '& label': {
      height: 'max-content',
    },
    '& h6': {
      fontSize: '16px',
      fontWeight: '400',
      margin: 0,
    },

    '& p': {
      marginTop: '5px',
      fontSize: '14px',
      fontWeight: 300,
    },

    '& span': {
      fontWeight: 400,
    },

    '& input:not(:checked) + .MuiSwitch-thumb': {
      border: '1px solid #747272',
    },
  },
  select: {
    marginRight: '16px',
  },
}));

const optionsLeverage = Array.from({ length: 1 }, (_, i) => `${i + 1}x`);
const orderTypes = ['limit', 'market'];

const SiteSettings = ({ closeSettings, trade }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [stakeFraction, setStakeFraction] = useState(
    trade.subscription.stake_amount || 100
  );
  const [entryOrderType, setEntryOrderType] = useState(
    trade.subscription.entry_ordertype
  );
  const [exitOrderType, setExitOrderType] = useState(
    trade.subscription.exit_ordertype
  );

  const { exchanges } = useSelector(
    (state) => state.personalDetails.personalDetails
  );
  // console.log(exchanges, subscriptions);
  // console.log('trade', trade);
  // console.log('trade.subscription.exID', trade.subscription.exID);
  const defaultExchangeIndex =
    exchanges.findIndex(
      (exchange) => exchange.id === trade.subscription.exID
    ) || 0;
  // console.log('default exchange index', defaultExchangeIndex);
  // console.log('trade.subscription.leverage', trade.subscription.leverage);
  const [openExchangeApi, setOpenExchangeApi] = React.useState(false);
  const anchorRefExchangeApi = React.useRef(null);
  const [selectedIndexExchangeApi, setSelectedIndexExchangeApi] =
    React.useState(defaultExchangeIndex);
  const exchangeID = exchanges ? exchanges[selectedIndexExchangeApi].id : 0;

  const optionsExchangeApi = exchanges?.length
    ? exchanges.map((ex) => ex.name)
    : ['No exchange API'];

  const [openLeverage, setOpenLeverage] = React.useState(false);
  const anchorRefLeverage = React.useRef(null);
  const [selectedIndexLeverage, setSelectedIndexLeverage] = React.useState(0);
  const [leverage, setLeverageValue] = React.useState(
    trade.subscription.leverage
  );

  const handleFormChange = (func, value) => {
    func(value);
  };

  const onSaveSettingsState = () => {
    const payload = {
      pair: trade.whitelist.pair,
      leverage,
      exchange_id: exchangeID,
      stake_fraction: stakeFraction / 100,
      entry_ordertype: entryOrderType,
      exit_ordertype: exitOrderType,
    };
    dispatch(updateSettings(payload));
    closeSettings();
  };

  return (
    <>
      <Box mb={4}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <ButtonGroup variant="contained" aria-label="outlined button group">
              <DropButton
                open={openExchangeApi}
                selectedIndex={selectedIndexExchangeApi}
                setOpen={setOpenExchangeApi}
                setSelectedIndex={setSelectedIndexExchangeApi}
                options={optionsExchangeApi}
                ref={anchorRefExchangeApi}
              />
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonGroup variant="contained" aria-label="outlined button group">
              <DropButton
                open={openLeverage}
                ref={anchorRefLeverage}
                selectedIndex={selectedIndexLeverage}
                setOpen={setOpenLeverage}
                setSelectedIndex={setSelectedIndexLeverage}
                options={optionsLeverage}
                isSlider
                setLeverageValue={setLeverageValue}
                slideValueDefault={trade.subscription.leverage || 1}
              />
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <div>
        <TextField
          label="Stake Fraction (%)"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={stakeFraction}
          className={classes.input}
          onChange={(e) => handleFormChange(setStakeFraction, e.target.value)}
          type="number"
          inputProps={{
            max: 100,
            min: 1,
            step: '1',
          }}
        />
      </div>
      <div className="settings-website-content">
        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Entry Order Type
              </InputLabel>
              <Select
                native
                label="Entry Order Type"
                onChange={(e) =>
                  handleFormChange(setEntryOrderType, e.target.value)
                }
                value={entryOrderType}
              >
                {orderTypes.map((ordertype) => (
                  <option value={ordertype} key={nanoid()}>
                    {ordertype}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Exit Order Type
              </InputLabel>
              <Select
                native
                label="Exit Order Type"
                onChange={(e) =>
                  handleFormChange(setExitOrderType, e.target.value)
                }
                value={exitOrderType}
              >
                {orderTypes.map((ordertype) => (
                  <option value={ordertype} key={nanoid()}>
                    {ordertype}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {/* <div className="customize-website-content-fields">
          <div className="customize-website-content-fields-line">
            <div className={classes.switchGroup}>
              <label htmlFor="keyboard-navigation">
                <h6>Enable Keyboard Navigation</h6>
                <p>
                  Adds visible focus indicators that meet WCAG guidelines while
                  tabbing through your web page.
                </p>
              </label>
              <Switch
                inputProps={{
                  name: 'keyboard-navigation',
                  id: 'keyboard-navigation',
                }}
                disableRipple
                onChange={() =>
                  handleFormChange(
                    'enableKeyboardNavigation',
                    !appearanceState.widgetConfigSiteAdditional
                      .enableKeyboardNavigation
                  )
                }
                checked={
                  appearanceState.widgetConfigSiteAdditional
                    .enableKeyboardNavigation
                }
              />
            </div>
          </div>
        </div> */}
      </div>

      <div className="add-website-fields">
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={onSaveSettingsState}
          style={{ width: '83px' }}
        >
          Save
        </PrimaryButton>
      </div>
    </>
  );
};

export default SiteSettings;

SiteSettings.propTypes = {
  trade: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  // setDomain: PropTypes.func.isRequired,
};
