import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const PrimaryButton = withStyles({
  root: {
    fontWeight: '500',
    height: '48px',
    fontSize: '16px',
    lineHeight: '150%',
    textTransform: 'none',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    '&:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#9AA5B1',
    },
  },
})(Button);

export const PrimaryMobileButton = withStyles({
  root: {
    fontWeight: '500',
    height: '36px',
    fontSize: '14px',
    lineHeight: '150%',
    textTransform: 'none',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    maxWidth: '159px',
    '&:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#9AA5B1',
    },
  },
})(Button);

export const FilledIconButton = withStyles({
  root: {
    border: '2px solid #C2F4D7',
    height: '36px',
    backgroundColor: 'rgb(74, 212, 147)',
    boxSizing: 'border-box',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '150%',
    textTransform: 'none',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    '&:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#9AA5B1',
    },
    '&:hover': {
      backgroundColor: 'rgb(51, 148, 102)',
    },
  },
})(Button);

export const IconButton = withStyles({
  root: {
    border: '2px solid #C2F4D7',
    height: '36px',
    boxSizing: 'border-box',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '150%',
    textTransform: 'none',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    '&:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#9AA5B1',
    },
  },
})(Button);
