import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, ButtonGroup } from '@material-ui/core';
import styled from '@emotion/styled';
import './Styles/trader.css';
import Container from '../common/Container';
import Trader from './Trader';
// import useStyles from './Trader/styles';
// import LoadingView from '../common/LoadingView';

import { DropButton } from '../common/DropdownButton';
import SearchField from '../common/AutoComplete';
// docker stop umuada_wordpress_1 umuada_db_1 resume_wordpress_1 resume_db_1
export const OrderBook = () => {
  const isDesktop = true;
  const [symbol, setSymbol] = useState('BTC');

  const [openExchangeApi, setOpenExchangeApi] = React.useState(false);
  const anchorRefExchangeApi = React.useRef(null);
  const [selectedIndexExchangeApi, setSelectedIndexExchangeApi] =
    React.useState(0);

  const { exchanges } = useSelector(
    (state) => state.personalDetails.personalDetails
  );

  const optionsExchangeApi = exchanges?.length
    ? exchanges.map((ex) => ex.name)
    : ['No exchange API'];

  console.log('exchanges', exchanges);
  const exchangeID = exchanges ? exchanges[selectedIndexExchangeApi].id : 0;
  const balance = exchanges ? exchanges[selectedIndexExchangeApi].balance : 0;

  return (
    <Container>
      {/* <LoadingView open={loading} /> */}
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4}>
          <Grid container>
            <ExApiBottonGroup
              variant="contained"
              aria-label="outlined button group"
            >
              <DropButton
                open={openExchangeApi}
                selectedIndex={selectedIndexExchangeApi}
                setOpen={setOpenExchangeApi}
                setSelectedIndex={setSelectedIndexExchangeApi}
                options={optionsExchangeApi}
                ref={anchorRefExchangeApi}
              />
            </ExApiBottonGroup>
          </Grid>
          <SearchField
            setSymbol={setSymbol}
            symbol={symbol}
            isDesktop={isDesktop}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Trader symbol={symbol} exchangeID={exchangeID} balance={balance} />
        </Grid>
      </Grid>
    </Container>
  );
};

/** Styled Components */

const ExApiBottonGroup = styled(ButtonGroup)`
  margin-bottom: 30px;
`;
