import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';

const FadeIn = ({ children, in: isIN, ...props }) => {
  const [isRendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  return (
    <Fade {...props} in={isIN} timeout={isRendered ? undefined : 0}>
      {children}
    </Fade>
  );
};

export default FadeIn;

FadeIn.propTypes = {
  in: PropTypes.bool,
  children: PropTypes.any,
};

FadeIn.defaultProps = {
  in: false,
  children: null,
};
