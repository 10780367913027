import { GET_TRADE_HISTORY_SUCCESS, GET_TRADE_HISTORY } from '../actionTypes';

const initialState = {
  history: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRADE_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case GET_TRADE_HISTORY_SUCCESS:
      console.log(action.value);
      console.log('{ ...state.history, ...{ [key]: value }', {
        ...state.history,
        ...{ [action.value.whitelist.pair]: action.value.trade_list },
      });
      return {
        history: {
          ...state.history,
          ...{ [action.value.whitelist.pair]: action.value.trade_list },
        },
        loading: false,
      };
    default: {
      return state;
    }
  }
}
