import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TextField,
  FormControl,
  Button,
  Grid,
  ButtonGroup,
} from '@material-ui/core';
import styled from '@emotion/styled';
import './Trader/styles.css';

import { DropButton } from '../common/DropdownButton';
import { addTradeEntry } from '../../store/actions';

const optionsMarginType = ['Cross Margin', 'Isolated Margin'];

const optionsLeverage = Array.from({ length: 1 }, (_, i) => `${i + 1}x`);

const optionsConditionals = ['Conditionals', 'Limit stop', 'Market stop'];

const dataFraction = [0.25, 0.5, 0.75, 1.0];

const TraderBook = ({ symbol, exchangeID, balance }) => {
  const dispatch = useDispatch();
  const [ordertype, setOrderType] = React.useState('limit');
  const [stopPrice, setStopPrice] = React.useState(0);
  const [limitPrice, setLimitPrice] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [openMarginType, setOpenMarginType] = React.useState(false);
  const anchorRefMarginType = React.useRef(null);
  const [selectedIndexMarginType, setSelectedIndexMarginType] =
    React.useState(0);

  const [openLeverage, setOpenLeverage] = React.useState(false);
  const anchorRefLeverage = React.useRef(null);
  const [selectedIndexLeverage, setSelectedIndexLeverage] = React.useState(0);
  const [leverage, setLeverageValue] = React.useState(1);

  const setSelectedIndexHandler = (index) => {
    setSelectedIndex(index);
    if (index !== 0) {
      setOrderType(optionsConditionals[index]);
    }
  };

  const setAmountHandler = (e) => {
    // console.log('setAmountHandler');
    if (limitPrice === 0) {
      setTotal(0);
      return;
    }
    const quantity = parseFloat(e.target.value);
    setAmount(quantity);
    setTotal(quantity * parseFloat(limitPrice));
  };

  const setTotalHandler = (e) => {
    // console.log('setAmountHandler');
    if (limitPrice === 0) {
      setAmount(0);
      return;
    }
    const totalStake = e.target.value;
    setTotal(totalStake);
    // TODO: Filter out the last zeros
    setAmount((totalStake / parseFloat(limitPrice)).toFixed(8));
  };

  const percentageHandler = (fraction) => {
    if (balance > 0 && limitPrice > 0) {
      const totalStake = balance & fraction;
      setTotal(totalStake);
      setAmount((totalStake / parseFloat(limitPrice)).toFixed(8));
    }
  };

  const tradeHandler = (side) => {
    const payload = {
      side,
      amount,
      symbol,
      balance,
      ordertype,
      stopPrice,
      exchangeID,
      leverage,
      stakeamount: total,
      price: parseFloat(limitPrice),
      marginType: optionsMarginType[selectedIndexMarginType],
    };
    // console.log(payload);
    setAmount(0);
    setTotal(0);
    dispatch(addTradeEntry(payload));
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <TraderBottonGroup
            variant="contained"
            aria-label="outlined button group"
          >
            <DropButton
              open={openMarginType}
              ref={anchorRefMarginType}
              selectedIndex={selectedIndexMarginType}
              setOpen={setOpenMarginType}
              setSelectedIndex={setSelectedIndexMarginType}
              options={optionsMarginType}
            />
          </TraderBottonGroup>
        </Grid>
        <Grid item>
          <TraderBottonGroup
            variant="contained"
            aria-label="outlined button group"
          >
            <DropButton
              open={openLeverage}
              ref={anchorRefLeverage}
              selectedIndex={selectedIndexLeverage}
              setOpen={setOpenLeverage}
              setSelectedIndex={setSelectedIndexLeverage}
              options={optionsLeverage}
              isSlider
              setLeverageValue={setLeverageValue}
            />
          </TraderBottonGroup>
        </Grid>
      </Grid>
      <TraderBottonGroup variant="string" aria-label="outlined button group">
        <Button onClick={() => setOrderType('limit')}>Limit</Button>
        <Button onClick={() => setOrderType('market')}>Market</Button>
        <DropButton
          open={open}
          ref={anchorRef}
          selectedIndex={selectedIndex}
          setOpen={setOpen}
          setSelectedIndex={setSelectedIndexHandler}
          options={optionsConditionals}
        />
      </TraderBottonGroup>
      {/stop/gi.test(ordertype) && (
        <FormControlBox fullWidth>
          {/* <InputLabel htmlFor="stop-price">Stop Price</InputLabel> */}
          <TextField
            id="stop-price"
            autoComplete="off"
            type="text"
            value={stopPrice}
            name="stop_price"
            onChange={(e) => setStopPrice(e.target.value)}
            variant="outlined"
            label="Stop Price"
            fullWidth
          />
        </FormControlBox>
      )}

      {ordertype !== 'market' && (
        <FormControlBox fullWidth>
          {/* <InputLabel htmlFor="limit-price">Limit Price</InputLabel> */}
          <TextField
            id="limit-price"
            autoComplete="off"
            type="text"
            value={limitPrice}
            name="limit"
            onChange={(e) => setLimitPrice(e.target.value)}
            variant="outlined"
            label="Limit Price"
            fullWidth
          />
        </FormControlBox>
      )}

      {ordertype !== 'market' && (
        <FormControlBox fullWidth>
          {/* <InputLabel htmlFor="amount">Amount</InputLabel> */}
          <TextField
            id="amount"
            autoComplete="off"
            type="number"
            value={amount}
            name="amount"
            onChange={(e) => setAmountHandler(e)}
            required
            variant="outlined"
            label="Amount"
            fullWidth
          />
        </FormControlBox>
      )}

      {ordertype !== 'market' && (
        <ul className="trade-percent">
          <Grid container>
            {dataFraction.map((fraction, index) => (
              <Grid item xs={3} sm={3} md={3} key={fraction}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <li
                  className="trade-percent-item"
                  onClick={() => percentageHandler(fraction)}
                  data-percent={fraction}
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                >
                  {fraction * 100} %
                </li>
              </Grid>
            ))}
          </Grid>
        </ul>
      )}

      <FormControlBox fullWidth>
        {/* <InputLabel htmlFor="total">Total</InputLabel> */}
        <TextField
          onChange={(e) => setTotalHandler(e)}
          value={total}
          id="total"
          type="number"
          name="total"
          autoComplete="off"
          variant="outlined"
          label="Total (USDT)"
          fullWidth
          required
        />
      </FormControlBox>

      <FormControlBox fullWidth>
        <Grid direction="row" container spacing={2}>
          <Grid item xs>
            <BuyButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => tradeHandler('long')}
            >
              Buy / Long
            </BuyButton>
          </Grid>
          <Grid item xs>
            <SellButton
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => tradeHandler('short')}
            >
              Sell / Short
            </SellButton>
          </Grid>
        </Grid>
      </FormControlBox>
    </Grid>
  );
};

export default TraderBook;

/** Styled Components */

const TraderBottonGroup = styled(ButtonGroup)`
  margin-bottom: 20px;
  border-color: #fff;
`;

const FormControlBox = styled(FormControl)`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const BuyButton = styled(Button)`
  color: #fff;
  font-weight: bold;
`;

const SellButton = styled(Button)`
  background-color: red;
  color: #fff;
  font-weight: bold;
`;

TraderBook.propTypes = {
  symbol: PropTypes.string.isRequired,
  exchangeID: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
};
