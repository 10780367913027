import { useCallback, useRef } from 'react';
import { useSnackbar } from 'notistack';

const useNotification = (options = {}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const opt = useRef(options);

  const notify = useCallback(
    (message, variant) =>
      enqueueSnackbar(
        {
          message,
          variant,
        },
        {
          ...(opt.current || {}),
          variant,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      ),
    [enqueueSnackbar]
  );

  return [notify, closeSnackbar];
};

export const NOTIFICATION_TYPE = Object.freeze({
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
});

export default useNotification;
