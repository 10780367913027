import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Button, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTransaction,
  setApiSuccessMessage,
  addSub,
} from '../../store/actions';
// import { useAnalytics } from '../../providers/AnalyticsProvider';

const PayPalButton = window.paypal?.Buttons.driver('react', {
  React,
  ReactDOM,
});

export const PayPalButtons = ({
  pair,
  closeModal,
  planPrice,
  onSuccess,
  // eslint-disable-next-line camelcase,react/prop-types
  plan_id,
}) => {
  const dispatch = useDispatch();
  // const { analytics } = useAnalytics();

  const user = useSelector((state) => state.user.user);

  const handleSubscription = () => {
    dispatch(addSub({ pair, planPrice, plan_id }));
  };

  const onError = (err) => {
    console.error('error from the onError callback', err);
    // analytics.track('Payment Status Updated', {
    //   status: 'error-processing',
    //   planId: plan_id,
    //   price: planPrice,
    //   pair,
    // });
  };

  const createSubscription = async (data, actions) => {
    const response = await actions.subscription.create({
      plan_id,
    });
    return response;
  };

  const onApprove = async (data) => {
    dispatch(
      addTransaction({
        transaction_id: data.subscriptionID,
        pair,
      })
    );

    // eslint-disable-next-line no-undef
    tap('conversion', data.subscriptionID.toString(), planPrice, {
      customer_id: user.user_id,
    });
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: 'payment',
      pair: pair,
    });

    dispatch(setApiSuccessMessage('Payment Successful'));
    // analytics.track('Payment Status Updated', {
    //   status: 'success',
    //   paymentId: data.subscriptionID,
    //   pair,
    //   price: planPrice,
    // });

    closeModal();
    setTimeout(() => {
      onSuccess();
    }, 500);
  };

  return (
    // <PayPalButton
    //   onApprove={onApprove}
    //   createSubscription={createSubscription}
    //   onError={onError}
    // />
    <>
      <Button onClick={handleSubscription} variant="outlined">
        Subscribe For Free
      </Button>
      <Box mt="20px" />
    </>
  );
};

PayPalButtons.propTypes = {
  pair: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  planPrice: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

PayPalButtons.defaultProps = {
  pair: '',
};
