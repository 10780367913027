import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import createReduxStore from './store';

import { SENTRY_API, SENTRY_SAMPLE_RATE, ENV } from './common/magicValues';

const store = createReduxStore();
Sentry.setTag('Repository', 'user-panel-fe');

Sentry.init({
  dsn: SENTRY_API,
  environment: ENV,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Number(SENTRY_SAMPLE_RATE),
});

ReactDOM.render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// //...
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://1c276134f8041d042ede75e0e7d84b2b@o4506065749082112.ingest.sentry.io/4506077678010368",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// const container = document.getElementById(“app”);
// const root = createRoot(container);
// root.render(<App />);
