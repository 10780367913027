import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';

import SnackMessage from '../components/SnackMessage';

const NotificationProvider = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={1}
      content={(key, notificationData) => {
        return (
          <SnackMessage
            id={key}
            message={notificationData.message}
            variant={notificationData.variant}
          />
        );
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
