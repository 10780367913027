import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useMediaQuery } from '../hooks/useMediaQuery';
// import { useQuery } from '../hooks/useQuery';
// import { useAnalytics } from '../providers/AnalyticsProvider';
// import { AriaLazy } from './Aria';
// import { getPersonalDetails } from '../common/helpers';
// import { setPersonalDetails, setToken } from '../store/actions';
// import { QUERY_PARAM_REDIRECT_TOKEN } from '../common/magicValues';
import { Header } from './Header';
import { Notifications } from './Notifications';
import {
  ManageWebsites,
  MobileNavigation,
  PasswordReset,
  PersonalDetails,
  OrderBook,
  SetExchangeApi,
} from './redesign';
import { EmailVerified } from './redesign/EmailVerified';
// import { Teams } from './redesign/Teams';
import { Statement } from './Statement';

const Main = () => {
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.user.user);
  // const redirectToken = useQuery().get(QUERY_PARAM_REDIRECT_TOKEN);

  // const personalDetails = useSelector(
  //   (state) => state.personalDetails.personalDetails
  // );
  // const { analytics } = useAnalytics();

  // const trackUser = useCallback(async () => {
  //   const accessToken = redirectToken ?? localStorage.getItem('accessToken');
  //   if (accessToken) {
  //     const personalDetailsResponse = await getPersonalDetails(accessToken);
  //     dispatch(setPersonalDetails(personalDetailsResponse.message));

  //     if (personalDetailsResponse.code === 200) {
  //       const { newAccount, isSpecialDomain, id, testAccount } =
  //         personalDetailsResponse.message;

  //       const userAction = `User Signed ${newAccount ? 'Up' : 'In'}`;
  //       analytics.alias(id);
  //       analytics.track(userAction, {
  //         user_id: user.user_id,
  //         is_special_domain: isSpecialDomain,
  //         is_test_user: testAccount,
  //       });
  //     }
  //   }
  // }, [redirectToken, dispatch, analytics, user.user_id]);

  // const identifyUser = useCallback(() => {
  //   if (!user.user_id) {
  //     return;
  //   }
  //   analytics.identify(user.user_id, {
  //     email: user.email,
  //     name: user.name,
  //   });
  // }, [user.user_id, user.email, user.name, analytics]);

  // useEffect(() => {
  //   identifyUser();
  // }, [identifyUser]);

  // useEffect(() => {
  //   trackUser();
  // }, [trackUser]);

  // useEffect(() => {
  //   if (redirectToken) {
  //     dispatch(setToken(redirectToken));
  //   }
  // }, [dispatch, redirectToken]);

  return (
    <Switch>
      <Route path="/statement" exact component={Statement} />
      <Route>
        {!isMobile && <Header />}
        {isMobile && <MobileNavigation />}
        <Notifications />
        <main className="main">
          <Switch>
            <Route path="/personal-details" component={PersonalDetails} />
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/verify-email" component={EmailVerified} />
            <Route path="/order-book" component={OrderBook} />
            <Route path="/exchange-api" component={SetExchangeApi} />

            {/* <Route path="/audit" component={AriaLazy} /> */}
            {/* <Route path="/team" component={Teams} /> */}
            {/* {personalDetails?.testAccount && (
            )} */}
            {/* <Route path="/invoices" component={Invoices} /> */}
            {/* <Route path="/email-address" component={EmailAddress} /> */}
            {/* <Route */}
            {/*  path="/billing-information" */}
            {/*  component={BillingInformation} */}
            {/* /> */}
            {/* <Route path="/support" component={SupportPage} /> */}
            <Route path="/assets" component={ManageWebsites} />
            <Route path="/" component={ManageWebsites} />
          </Switch>
        </main>
      </Route>
    </Switch>
  );
};

export default Main;
