import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { Popover, Box } from '@material-ui/core';
import * as Sentry from '@sentry/react';

import { navigateToLoginURL } from '../common/navigate';
import { useQuery } from '../hooks/useQuery';
import { QUERY_PARAM_REDIRECT_TOKEN } from '../common/magicValues';
import { setPersonalDetails, setToken } from '../store/actions';
import HeaderLogo from './redesign/HeaderLogo';

// import { Link, Container } from '@material-ui/core';
export const Header = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.user);

  const personalDetails = useSelector(
    (state) => state.personalDetails.personalDetails
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const redirectToken = useQuery().get(QUERY_PARAM_REDIRECT_TOKEN); // Auth token??

  useEffect(() => {
    if (redirectToken) {
      dispatch(setToken(redirectToken));
    }
  }, [dispatch, redirectToken]);

  // Setting Personal data details to the store for future use.
  const setPersonalDetailsData = useCallback(async () => {
    const accessToken = redirectToken ?? token;
    if (accessToken) {
      console.log('attempt at get personal details');
      dispatch(setPersonalDetails(accessToken));
    }
  }, [dispatch, token, redirectToken]);

  useEffect(() => {
    setPersonalDetailsData();
  }, [setPersonalDetailsData]);

  useEffect(() => {
    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: user.user_id,
        name: user.name,
        email: user.email,
      });
    });
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const calendlyCall = (e) => {
  //   e.preventDefault();
  //   window.showCalendly();
  //   return false;
  // };

  console.log('personalDetails', personalDetails);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className="header-root" style={{ background: '#1e4356' }}>
      <div className="header">
        {/* <div className="header-icon">
          <HeaderLogo />
        </div> */}
        <NavLink
          exact
          to="/"
          className="header-manage-websites-link"
          // activeClassName="link-active"
        >
          <HeaderLogo />
        </NavLink>
        <Box ml="46px" />

        <NavLink
          exact
          to="/assets"
          className="header-manage-websites-link"
          activeClassName="link-active"
        >
          Assets
        </NavLink>
        <Box ml="10px" />
        <NavLink
          exact
          to="/order-book"
          className="header-manage-websites-link"
          activeClassName="link-active"
        >
          Orders
        </NavLink>
        <Box ml="10px" />
        {/* <NavLink
          exact
          to="/audit"
          className="header-manage-websites-link"
          activeClassName="link-active"
        >
          Audit
        </NavLink>
        <Box ml="10px" /> */}
        {/* <NavLink
          exact
          to="/services"
          className="header-manage-websites-link"
          activeClassName="link-active"
        >
          Services
        </NavLink>
        <Box ml="10px" /> */}
      </div>
      <div className="header-navigation">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          aria-roledescription="open navigation"
          onClick={handleClick}
          className="header-user-name"
        >
          <div>{user.name}</div>
          <img
            className="header-user-name-icon"
            src="chevron_down.png"
            alt="open navigation"
          />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="header-navigation-popover">
            <Link
              to="/personal-details"
              className="header-navigation-popover-item"
              onClick={handleClose}
            >
              Personal Details
            </Link>
            {/* <Link */}
            {/*  to="/billing-information" */}
            {/*  className="header-navigation-popover-item" */}
            {/*  onClick={handleClose} */}
            {/* > */}
            {/*  Billing Information */}
            {/* </Link> */}
            {/* <Link */}
            {/*  to="/invoices" */}
            {/*  className="header-navigation-popover-item" */}
            {/*  onClick={handleClose} */}
            {/* > */}
            {/*  Invoices */}
            {/* </Link> */}
            {/* <Link
              to="/password-reset"
              className="header-navigation-popover-item"
              onClick={handleClose}
            >
              Password Reset
            </Link> */}
            {/* <Link */}
            {/*  onClick={handleClose} */}
            {/*  to="/email-address" */}
            {/*  className="header-navigation-popover-item" */}
            {/* > */}
            {/*  Email Address */}
            {/* </Link> */}
            <Link
              to="/exchange-api"
              className="header-navigation-popover-item"
              onClick={handleClose}
            >
              API Keys
            </Link>
            {/* <Link
              onClick={handleClose}
              to={{ pathname: process.env.REACT_APP_HTTP_SUPPORT_ENDPOINT }}
              target="_blank"
              className="header-navigation-popover-item"
            >
              Support
            </Link> */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <Link
              onClick={calendlyCall}
              className="header-navigation-popover-item"
            >
              Contact Sales
            </Link> */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
            <Link
              onClick={() => navigateToLoginURL()}
              className="header-navigation-popover-item"
            >
              Logout
            </Link>
          </div>
        </Popover>
      </div>
    </div>
  );
};
