import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { StyledButton } from '../styled';

export const ContainedButton = ({ children, ...props }) => {
  return (
    <StyledContained color="primary" {...props} variant="contained">
      {children}
    </StyledContained>
  );
};

ContainedButton.propTypes = {
  children: PropTypes.any.isRequired,
};

export const OutlinedButton = ({ children, ...props }) => {
  return (
    <StyledOutline color="primary" {...props} variant="outlined">
      {children}
    </StyledOutline>
  );
};

OutlinedButton.propTypes = {
  children: PropTypes.any.isRequired,
};

/** Styled Components */

const StyledContained = styled(StyledButton)`
  &:hover {
    background: #98eabe;
  }
`;

const StyledOutline = styled(StyledButton)`
  && {
    border: 2px solid;
    border-color: #c2f4d7;
    line-height: 130%;
  }

  & .MuiButton-label {
    color: ${({ theme }) => theme.palette.on.darkHigh};
  }

  &:hover {
    border: 2px solid;
    border-color: #c2f4d7;
    background: #ecfcf2;
  }
`;
