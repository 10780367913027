import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  select: {
    marginRight: '16px',
  },
}));

export const AddDomainLine = ({ index, domainChanged }) => {
  const classes = useStyles();
  const [domain, setDomain] = useState('');

  return (
    <div className="add-website-fields">
      <TextField
        label="Website URL"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        value={domain}
        onChange={(e) => {
          setDomain(e.target.value);
          domainChanged(e.target.value, index);
        }}
        className={classes.input}
      />
    </div>
  );
};

AddDomainLine.propTypes = {
  index: PropTypes.number.isRequired,
  domainChanged: PropTypes.func.isRequired,
};
