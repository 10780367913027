import { buildLoginURL } from './buildURL';

const isValidURLString = (url) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const _ = new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const navigateToExternalURL = (url) => {
  if (isValidURLString(url)) {
    window.location.assign(url);
  }
};

export const navigateToLoginURL = () => {
  const url = buildLoginURL();
  navigateToExternalURL(url);
};
