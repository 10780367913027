import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { ReactComponent as TickIcon } from '../../img/CheckboxIcon.svg';

const CheckmarkIcon = ({ Icon, children, ...props }) => {
  const IconComponent = Icon ?? TickIcon;
  const icon = children || <IconComponent />;
  return <CheckmarkIconContainer {...props}>{icon}</CheckmarkIconContainer>;
};

CheckmarkIcon.propTypes = {
  Icon: PropTypes.any,
  children: PropTypes.any,
};

CheckmarkIcon.defaultProps = {
  Icon: null,
  children: null,
};

export default CheckmarkIcon;

/** Styled Components */

export const CenterBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckmarkIconContainer = styled(CenterBlock, {
  shouldForwardProp: (prop) => prop !== 'light',
})`
  border-width: 2px;
  border-style: solid;

  border-radius: 50%;

  width: 32px;
  height: 32px;

  flex-shrink: 0;
`;
