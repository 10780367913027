import { GET_TRADES_SUCCESS, GET_MORE_TRADES } from '../actionTypes';

const initialState = {
  trades: [],
  metaInfo: {},
  loading: false,
  loadingMoreTrades: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MORE_TRADES:
      return { ...state, loadingMoreTrades: true };
    case GET_TRADES_SUCCESS:
      return {
        ...state,
        trades: action.value.message ? action.value.message : action.value,
        loading: false,
        loadingMoreTrades: false,
        metaInfo: action.value.metaInfo ?? {
          ...state.metaInfo,
          page: 1,
          Next: state.metaInfo.PerPage < state.metaInfo.TotalCount ? 2 : null,
        },
      };
    default: {
      return state;
    }
  }
}
