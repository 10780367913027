import React from 'react';
import { Slide, Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { setApiError, setApiSuccessMessage } from '../store/actions';

export const Notifications = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.navigation.error);
  const successMessage = useSelector(
    (state) => state.navigation.successMessage
  );
  const autoHide = useSelector((state) => state.navigation.autoHide);
  const errorVisible = errorMessage !== '';
  const successMessageVisible = successMessage !== '';

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setApiError(''));
  };

  const handleCloseSuccessMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setApiSuccessMessage(''));
  };

  // const handleChange = (event, newValue) => {
  //   dispatch(changeTab(newValue));
  // };

  return (
    <div>
      <Snackbar
        open={errorVisible}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
        onClose={handleCloseError}
      >
        <MuiAlert onClose={handleCloseError} variant="filled" severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={successMessageVisible}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={autoHide ? 6000 : null}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
        onClose={handleCloseSuccessMessage}
      >
        <MuiAlert
          onClose={handleCloseSuccessMessage}
          variant="filled"
          severity="success"
        >
          {successMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
