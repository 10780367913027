import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import FadeIn from './FadeIn';

import { ReactComponent as LogoIcon } from './img/screener.logo.svg';
import { ReactComponent as LogoScreenerBot } from './img/screener.text.svg';

const HeaderLogo = ({ justLogo }) => {
  return (
    <Container>
      <LogoIconDesign alt="Logo - screenerbot robot figure icon" />
      <Box ml="5px" />
      <FadeIn in={!justLogo} unmountOnExit>
        <LogoScreenerBot />
      </FadeIn>
    </Container>
  );
};

export default HeaderLogo;

HeaderLogo.propTypes = {
  justLogo: PropTypes.bool,
};

HeaderLogo.defaultProps = {
  justLogo: false,
};

/** Styled Components */

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 80%;
  }
`;

const LogoIconDesign = styled(LogoIcon)`
  margin-top: -20px;
`;
