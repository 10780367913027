import React, { useCallback, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { Container, Box, Grid } from '@material-ui/core';

import { useQuery } from '../../hooks/useQuery';
import { QUERY_PARAM_STATEMENT_DOMAIN } from '../../common/magicValues';

import { TypographyH1 } from '../common/styled';
import Underline from '../common/Underline';
import { OutlinedButton } from '../common/ActionButton';
import { ReactComponent as DownloadIcon } from '../../img/DownloadIcon.svg';
import { ReactComponent as LogoSmall } from '../../img/LogoDark.svg';
import { useAnalytics } from '../../providers/AnalyticsProvider';

export const Statement = () => {
  const urlDomain = useQuery().get(QUERY_PARAM_STATEMENT_DOMAIN);
  const { analytics } = useAnalytics();

  useEffect(() => {
    analytics.page({
      name: 'Accessibility Statement Page',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.zE?.('webWidget', 'hide');

    const timeoutID = setTimeout(() => {
      window.zE?.('webWidget', 'hide');
    }, 1000);

    return () => {
      clearTimeout(timeoutID.current);
      window.zE?.('webWidget', 'show');
    };
  }, []);

  // TODO: Move to helpers
  const formattedDomain = useMemo(() => {
    if (!urlDomain) {
      return undefined;
    }

    let domain = urlDomain;
    if (urlDomain && !urlDomain.startsWith('http')) {
      domain = `http://${urlDomain}`;
    }

    try {
      const url = new URL(domain);
      return url.hostname;
    } catch {
      return urlDomain;
    }
  }, [urlDomain]);

  const formattedDate = useMemo(
    () =>
      new Date().toLocaleDateString(undefined, {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
      }),
    []
  );

  const doPrint = useCallback(() => window.print(), []);

  return (
    <Main>
      <Container maxWidth="sm">
        <HeaderContent>
          <HeaderText>
            <Underline space={4}>Accessibility statement</Underline>
          </HeaderText>

          <Box mt="26px" />
          <Meta>
            <Grid container wrap="nowrap" spacing={3} alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>Issued by</Grid>
                  <Grid item>
                    <LogoMark width="94" height="34" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>{formattedDate}</Grid>
              {formattedDomain && <Grid item>{formattedDomain}</Grid>}
            </Grid>
          </Meta>
          <Box mt="24px" />
          <DownloadSection>
            <DownloadButton startIcon={<DownloadIcon />} onClick={doPrint}>
              Download or Print
            </DownloadButton>
          </DownloadSection>
        </HeaderContent>
      </Container>
      <Background>
        <Container maxWidth="sm">
          <StatementContent>
            <SectioTitle>Compliance status</SectioTitle>
            <SectionText>
              <p>
                We firmly believe that the internet should be available and
                accessible to anyone, and are committed to providing a website
                that is accessible to the widest possible audience, regardless
                of circumstance and ability.
              </p>
              <p>
                To fulfill this, we aim to adhere as strictly as possible to the
                World Wide Web Consortium’s (W3C) Web Content Accessibility
                Guidelines 2.1 (WCAG 2.1) at the AA level. These guidelines
                explain how to make web content accessible to people with a wide
                array of disabilities. Complying with those guidelines helps us
                ensure that the website is accessible to all people: blind
                people, people with motor impairments, visual impairment,
                cognitive disabilities, and more.
              </p>
              <p>
                This website utilizes various technologies that are meant to
                make it as accessible as possible at all times. We utilize an
                accessibility interface that allows persons with specific
                disabilities to adjust the website’s UI (user interface) and
                design it to their personal needs.
              </p>
              <p>
                Additionally, the website utilizes an AI-based application that
                runs in the background and optimizes its accessibility level
                constantly. This application remediates the website’s HTML,
                adapts its functionality and behavior for screen-readers used by
                blind users, and for keyboard functions used by individuals with
                motor impairments.
              </p>
              <p>
                If you wish to contact the website&apos;s owner please use the
                following email test@test.com.
              </p>
            </SectionText>
            <PageBreak />
            <br />
            <SectioTitle>Screen-reader and keyboard navigation</SectioTitle>
            <SectionText>
              <p>
                Our website implements the ARIA attributes (Accessible Rich
                Internet Applications) technique, alongside various different
                behavioral changes, to ensure blind users visiting with
                screen-readers are able to read, comprehend, and enjoy the
                website’s functions. As soon as a user with a screen-reader
                enters your site, they immediately receive a prompt to enter the
                Screen-Reader Profile so they can browse and operate your site
                effectively. Here’s how our website covers some of the most
                important screen-reader requirements, alongside console
                screenshots of code examples:
              </p>
              <ul>
                <li>
                  <b>Screen-reader optimization</b>: we run a background process
                  that learns the website’s components from top to bottom, to
                  ensure ongoing compliance even when updating the website. In
                  this process, we provide screen-readers with meaningful data
                  using the ARIA set of attributes. For example, we provide
                  accurate form labels; descriptions for actionable icons
                  (social media icons, search icons, cart icons, etc.);
                  validation guidance for form inputs; element roles such as
                  buttons, menus, modal dialogues (popups), and others.
                </li>
                <li>
                  <b>Keyboard navigation optimization</b>: The background
                  process also adjusts the website’s HTML, and adds various
                  behaviors using JavaScript code to make the website operable
                  by the keyboard. This includes the ability to navigate the
                  website using the Tab and Shift+Tab keys, operate dropdowns
                  with the arrow keys, close them with Esc, trigger buttons and
                  links using the Enter key, navigate between radio and checkbox
                  elements using the arrow keys, and fill them in with the
                  Spacebar or Enter key.
                </li>
              </ul>
            </SectionText>
          </StatementContent>
        </Container>
      </Background>
    </Main>
  );
};

/** Styled Components */

const Main = styled.main`
  @media print {
    @page {
      size: auto;
      margin: 0;
    }
  }
`;

const DownloadSection = styled.div`
  @media print {
    display: none;
  }
`;

const HeaderContent = styled.div`
  padding-top: 48px;
  padding-bottom: 64px;

  @media print {
    padding-bottom: initial;
  }
`;

const StatementContent = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;

  @media print {
    padding: initial;
  }
`;

const HeaderText = styled(TypographyH1)`
  font-weight: 500;
  font-size: 34px;
  line-height: 120%;

  color: #000a14;
`;

const Meta = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  color: rgba(0, 10, 20, 0.85);
`;

const DownloadButton = styled(OutlinedButton)`
  text-transform: none;
  padding: 6px 16px;
  border-radius: 8px;
`;

const SectioTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;

  color: #000a14;
`;

const SectionText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;

  color: rgba(0, 10, 20, 0.65);

  p {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  & ul > li {
    margin-bottom: 24px;
  }
`;

const PageBreak = styled.div`
  @media print {
    page-break-after: always;
  }
`;

const Background = styled.div`
  background: #f5f7fa;
  @media print {
    background: none;
  }
`;

const LogoMark = styled(LogoSmall)`
  margin-top: 6px;
`;
