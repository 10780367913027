import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, CardContent, Typography } from '@material-ui/core';
import { FilledIconButton, IconButton } from './Controls';

export const WebsiteCard = ({
  trade,
  openCustomAppearance,
  openTradeHistory,
  openCertification,
  openEditSub,
  openSettings,
}) => {
  // let daysLeft = '';
  // let freeTrial = false;
  // let daysDifference = 0;
  // // Expected Duration
  // const expDate = new Date(trade.expiration);
  // const regDate = new Date(trade.trialRegDate);
  // const timedifference = expDate.getTime() - regDate.getTime();
  // daysDifference = Math.ceil(timedifference / (1000 * 3600 * 24));

  // if (!trade.level && !trade.isEditable && trade.isActive) {
  //   // Free trial must be less than 40 day
  //   if (daysDifference < 40) {
  //     freeTrial = true;
  //   }
  // }

  // if (trade.expiration) {
  //   const expiration = moment(trade.expiration);
  //   const monthlyPayMax = 200;

  //   if (expiration.isAfter(moment())) {
  //     // Add up free trial and payment days
  //     if (trade.level && daysDifference && daysDifference <= 15) {
  //       // 1 month subscription
  //       if (trade.payment_amount <= monthlyPayMax) expiration.add(30, 'days');
  //       // 1 year subscription
  //       else expiration.add(365, 'days');
  //     }
  //     daysLeft = expiration.fromNow().replace('in ', '');
  //     daysLeft += ' LEFT';
  //   }
  // }

  const { pair, price } = trade.whitelist;
  const symbol = pair.replace(':USDT', '');
  const lastTrade = trade.trade_list.slice(-1)[0];
  // console.log('LastTrade =>', lastTrade);
  let uPNL = 0.0;
  if (lastTrade.is_active) {
    uPNL = lastTrade.is_short
      ? (lastTrade.open_rate - price) / lastTrade.open_rate
      : (price - lastTrade.open_rate) / lastTrade.open_rate;
  }

  const roi =
    100 * trade.trade_list.reduce((acc, tradeEntry) => acc + tradeEntry.roi, 0);
  return (
    <div className="website-card">
      {/* Card Name */}
      <div className="website-card-header">
        {/* <div className="website-card-header-text">trade.name</div> */}
        <Card
          style={{
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to bottom right, #ffffff, #eeeeee)',
            // backgroundColor: '#bdbdbd',
          }}
        >
          <CardContent>
            <Typography variant="h5" component="h2">
              <strong>{symbol}</strong>
            </Typography>
            <Typography color="textSecondary">${price}</Typography>
            <Typography color="textSecondary">
              <small>30d</small> ROI: {roi.toFixed(2)}%
            </Typography>
            <Typography color="textSecondary">
              change: {uPNL.toFixed(2)}%
            </Typography>
          </CardContent>
        </Card>
      </div>
      {/* Card Tags */}
      {/* <div className="website-card-tags">
        <div
          className={`website-card-tag ${
            trade.isActive
              ? 'website-card-tag-active'
              : 'website-card-tag-inactive'
          }`}
          onClick={() => openEditSub(trade)}
        >
          {trade.isActive ? 'Active' : 'Inactive'}
        </div>
        {freeTrial && (
          <div
            className="website-card-tag website-card-tag-trial"
            onClick={() => openEditSub(trade)}
          >
            Free Trial
          </div>
        )}
        {trade.level && (
          <div
            className="website-card-tag website-card-tag-level"
            onClick={() => openEditSub(trade)}
          >
            {trade.level.split(' ')[0]}
          </div>
        )}
        {daysLeft && trade.isActive && (
          <div
            className="website-card-tag website-card-tag-days-left"
            onClick={() => openEditSub(trade)}
          >
            {daysLeft}
          </div>
        )}
      </div> */}
      {/*  Card Info */}
      <div className="website-card-controls">
        {trade.isEditable ? (
          <IconButton
            variant="outlined"
            style={{ margin: '0px 12px 12px 0px', justifyContent: 'start' }}
            onClick={() => openEditSub(trade)}
            color="secondary"
          >
            <>
              <img
                className="website-card-header-edit-btn-ico"
                alt="edit website"
                src="edit.png"
              />
              <div className="website-card-controls-btn-text">Manage</div>
            </>
          </IconButton>
        ) : (
          <FilledIconButton
            variant="outlined"
            style={{ margin: '0px 12px 12px 0px', justifyContent: 'start' }}
            onClick={() => openEditSub(trade)}
            color="secondary"
          >
            <>
              <img
                className="website-card-header-edit-btn-ico"
                alt="edit website"
                src="edit.png"
              />
              <div className="website-card-controls-btn-text">
                Register Asset
              </div>
            </>
          </FilledIconButton>
        )}
        <IconButton
          variant="outlined"
          style={{ margin: '0px 12px 12px 0px', justifyContent: 'start' }}
          onClick={() => openTradeHistory(trade)}
          color="secondary"
        >
          <>
            <img
              className="website-card-header-edit-btn-ico"
              alt="installation"
              src="code.png"
            />
            <div className="website-card-controls-btn-text">Trade history</div>
          </>
        </IconButton>
        <IconButton
          variant="outlined"
          style={{ margin: '0px 12px 12px 0px', justifyContent: 'start' }}
          color="secondary"
          onClick={() => openSettings(trade)}
        >
          <>
            <img
              className="website-card-header-edit-btn-ico"
              alt="settings"
              src="settings.png"
            />
            <div className="website-card-controls-btn-text">
              Advanced Settings
            </div>
          </>
        </IconButton>
      </div>
    </div>
  );
};

WebsiteCard.propTypes = {
  trade: PropTypes.object.isRequired,
  openTradeHistory: PropTypes.func.isRequired,
  openCertification: PropTypes.func.isRequired,
  openCustomAppearance: PropTypes.func.isRequired,
  openEditSub: PropTypes.func.isRequired,
  openSettings: PropTypes.func.isRequired,
};
