import { SET_TOKEN } from '../actionTypes';
// eslint-disable-next-line import/named
import { parseJwt } from '../../common/firebase';

// eslint-disable-next-line no-undef
const token =
  localStorage.getItem('accessToken') ||
  'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFkNWM1ZTlmNTdjOWI2NDYzYzg1ODQ1YTA4OTlhOWQ0MTI5MmM4YzMiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiXyBTYWIgXyIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQUZkWnVjcm8wQ1hkYmlvcmpxNnp6MXJjZEpRME9TcnFVRFNPUHlPeFB1dDQ9czk2LWMiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZXF1YWxseS1wcmQiLCJhdWQiOiJlcXVhbGx5LXByZCIsImF1dGhfdGltZSI6MTY5NjE1Njg5OCwidXNlcl9pZCI6ImI0NE4yOUlQMjRadWl4enpEaWJFdnlmRFdtZDIiLCJzdWIiOiJiNDROMjlJUDI0WnVpeHp6RGliRXZ5ZkRXbWQyIiwiaWF0IjoxNjk2MTU2ODk4LCJleHAiOjE2OTYxNjA0OTgsImVtYWlsIjoic29tbXlzYWJ1ZGVoQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTE1OTI1NzAzOTI5MDE0MTM5MzI4Il0sImVtYWlsIjpbInNvbW15c2FidWRlaEBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.fAi8xI9HAKGXIvQjo7x9bK4y31Y3vZ1EGBv-C3TH2aJxxzuRNgeyk7fN65Re__-1uznq-eARjxUvdrfKRyQBgDSGH10jYx85AyEbXZgfuiXGhQ583aBxNCy9CwhPBFdGOAJY-5SBu0dhlthbtbnVdOTGp9xFXn93-69BjeygTdkT2qY-MLX0bSyXCG6sPPgp6yIqu256WRMG67DYF-4ouN1BkF3XQZyYc8JU49ae_udfntLvTjgqcD7NDaNORqos_Ce5lBWQMg3osCdbBKfF1j6iJKVRmpjSx-JOnRqJmk-T4lVre6U1p7ENFc-1hYPa-DXOYY63dpncCuMMsk5mtw';

const initialState = {
  user: token ? parseJwt(token) : {},
  token: token,
};

export default function (state = initialState, action) {
  const urlParams = new URLSearchParams(window.location.search);
  const isSignup = urlParams.get('signup');
  switch (action.type) {
    case SET_TOKEN:
      // registration with google tracking
      if (isSignup && isSignup === 'true') {
        // eslint-disable-next-line no-undef
        dataLayer.push({
          event: 'login',
          loginType: 'registrationWithGoogle',
        });
        // eslint-disable-next-line no-undef
        tap('trial', state.user.user_id);
      }
      // login with google tracking
      if (isSignup && isSignup === 'false') {
        // eslint-disable-next-line no-undef
        dataLayer.push({
          event: 'login',
          loginType: 'loginWithGoogle',
        });
      }
      return { ...state, user: parseJwt(action.value), token: action.value };
    default: {
      return state;
    }
  }
}
