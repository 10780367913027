import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { SEGMENT_WRITE_KEY } from '../common/magicValues';
import { HMAC } from '../common/helpers';

const initialAnalytics = {
  identify: () => {},
  track: () => {},
  page: () => {},
  group: () => {},
  alias: () => {},
};

export const AnalyticsContext = createContext(initialAnalytics);

export const AnalyticsProvider = ({ children }) => {
  const analyticsRef = useRef();
  const bufferCallsRef = useRef([]);
  const hmacRef = useRef(null);

  const getIntercomHash = useCallback(async (userId) => {
    if (userId && !hmacRef.current) {
      hmacRef.current = await HMAC(
        process.env.REACT_APP_INTERCOM_WEB_IDENTIFICATION_KEY,
        userId.toString()
      );
    }

    return hmacRef.current;
  }, []);

  const identify = useCallback(
    async (...args) => {
      const analytics = analyticsRef.current;
      const bufferCalls = bufferCallsRef.current;
      const [id] = args;
      const hmac = await getIntercomHash(id);
      const intercomSettings = { user_hash: hmac };

      args.push({
        context: { Intercom: intercomSettings },
      });

      if (analytics) {
        analytics.identify(...args);
      } else {
        bufferCalls.push({ type: 'identify', args });
      }
    },
    [getIntercomHash]
  );

  const track = useCallback(async (...args) => {
    const analytics = analyticsRef.current;
    const bufferCalls = bufferCallsRef.current;

    if (analytics) {
      analytics.track(...args);
    } else {
      bufferCalls.push({ type: 'track', args });
    }
  }, []);

  const page = useCallback(async (...args) => {
    const analytics = analyticsRef.current;
    const bufferCalls = bufferCallsRef.current;

    if (analytics) {
      analytics.page(...args);
    } else {
      bufferCalls.push({ type: 'page', args });
    }
  }, []);

  const group = useCallback(async (...args) => {
    const analytics = analyticsRef.current;
    const bufferCalls = bufferCallsRef.current;

    if (analytics) {
      analytics.group(...args);
    } else {
      bufferCalls.push({ type: 'group', args });
    }
  }, []);
  const alias = useCallback(async (...args) => {
    const analytics = analyticsRef.current;
    const bufferCalls = bufferCallsRef.current;

    if (analytics) {
      analytics.alias(...args);
    } else {
      bufferCalls.push({ type: 'alias', args });
    }
  }, []);

  useEffect(() => {
    const loadAnalytics = async () => {
      const [analytics] = await AnalyticsBrowser.load({
        writeKey: SEGMENT_WRITE_KEY,
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const call of bufferCallsRef.current) {
        // eslint-disable-next-line no-await-in-loop
        await analytics[call.type](...call.args);
      }

      analyticsRef.current = analytics;
      bufferCallsRef.current = [];
    };
    loadAnalytics();
  }, []);

  const customAnalytics = useMemo(
    () => ({ identify, page, track, group, alias }),
    [identify, page, track, group, alias]
  );
  return (
    <AnalyticsContext.Provider value={{ analytics: customAnalytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => useContext(AnalyticsContext);
