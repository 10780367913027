import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Box } from '@material-ui/core';
import styled from '@emotion/styled';

import { TypographySubtitle1, CenterBlock } from '../styled';

import PresentationModal from '../PresentationModal';

/** value 0-100 */
const LoadingView = ({ open, label }) => {
  return (
    <PresentationModal open={open}>
      <ProgressBar variant="indeterminate" />
      <Box mt="16px" />
      <CenterBlock>
        <Description component="span">{label}</Description>
      </CenterBlock>
    </PresentationModal>
  );
};

LoadingView.propTypes = {
  open: PropTypes.bool,
  label: PropTypes.string,
};

LoadingView.defaultProps = {
  open: false,
  label: '',
};

export default LoadingView;

/** Styled Components */

const ProgressBar = styled(LinearProgress)`
  height: 8px;
  border-radius: 5px;
  width: min(512px, 70vw);
`;

const Description = styled(TypographySubtitle1)`
  color: ${({ theme }) => theme.palette.on.darkLow};
`;
