// import {} from "../actionTypes";

import { API_ERROR, TAB_CHANGED, API_SUCCESS } from '../actionTypes';

const initialState = {
  tab: '/',
  error: '',
  successMessage: '',
  autoHide: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case API_ERROR: {
      return {
        ...state,
        error: action.value,
        autoHide: action?.autoHide ?? true,
      };
    }
    case API_SUCCESS: {
      return {
        ...state,
        successMessage: action.value,
        autoHide: action?.autoHide ?? true,
      };
    }
    case TAB_CHANGED: {
      return {
        ...state,
        tab: action.value,
        error: '',
      };
    }
    default: {
      return state;
    }
  }
}
