import React from 'react';
import { TextField, Select, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton } from './Controls';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
  inputSmall: {
    marginBottom: '28px',
    width: '268px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
  inputMulti: {
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

export const SupportPage = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  return (
    <div className="content-page">
      <div className="content-page-container">
        <div className="header-page">
          <div className="header-page-text">24/7 support</div>
          <div className="header-page-sub-text">
            We are available 24/7 and will respond within 24 hours. Before
            sending a question make sure to check out our FAQs section.
          </div>
        </div>
        <div className="content-page-form">
          <div className="content-page-container">
            <TextField
              label="Name"
              defaultValue=""
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={isMobile ? { width: '100%' } : { marginRight: '24px' }}
              className={classes.inputSmall}
            />
            <TextField
              className={classes.inputSmall}
              label="Email"
              defaultValue=""
              style={isMobile ? { width: '100%' } : {}}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="topic">Topic</InputLabel>
            <Select
              native
              variant="outlined"
              className={classes.input}
              label="Topic"
              inputProps={{
                name: 'topic',
                id: 'topic',
              }}
            >
              <option value="" disabled>
                Please select
              </option>
              <option value={10}>1</option>
              <option value={20}>2</option>
              <option value={30}>3</option>
            </Select>
          </FormControl>
          <div className="content-page-container">
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={isMobile ? { width: '100%' } : {}}
            >
              <InputLabel htmlFor="subject">Subject</InputLabel>
              <Select
                native
                style={isMobile ? { width: '100%' } : { marginRight: '24px' }}
                className={classes.inputSmall}
                variant="outlined"
                label="Subject"
              >
                <option value="" disabled>
                  Please select
                </option>
                <option value={10}>1</option>
                <option value={20}>2</option>
                <option value={30}>3</option>
              </Select>
            </FormControl>
            <TextField
              style={isMobile ? { width: '100%' } : {}}
              className={classes.inputSmall}
              label="Or enter your own subject"
              defaultValue=""
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </div>
          <TextField
            label="Your Question"
            className={classes.inputMulti}
            multiline
            defaultValue=""
            InputLabelProps={{ shrink: true }}
            rows="4"
            style={{ width: '100%' }}
            variant="outlined"
          />
          <PrimaryButton
            color="primary"
            style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
            variant="contained"
          >
            Send Message
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
