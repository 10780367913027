import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  MenuList,
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  Slider,
  Box,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled from '@emotion/styled';
// import './Trader/styles.css';

const marks = [
  {
    value: 1,
    label: '1x',
  },
  {
    value: 25,
    label: '25x',
  },
  {
    value: 50,
    label: '50x',
  },
  {
    value: 75,
    label: '75x',
  },
  {
    value: 100,
    label: '100x',
  },
];

function valuetext(value) {
  return `${value}x`;
}

const NumberSlider = ({ slideValue, setSlideValue }) => {
  const handleSlideChange = (event, newValue) => {
    setSlideValue(newValue);
  };

  return (
    <div>
      <SliderBox>
        <Slider
          value={slideValue}
          onChange={handleSlideChange}
          aria-labelledby="continuous-slider"
          step={1}
          min={1}
          max={100}
          defaultValue={1}
          getAriaValueText={valuetext}
          marks={marks}
        />
        <p>{slideValue}x Leverage</p>
      </SliderBox>
    </div>
  );
};

export const DropButton = forwardRef(
  (
    {
      open,
      selectedIndex,
      setOpen,
      setSelectedIndex,
      options,
      isSlider,
      setLeverageValue,
      slideValueDefault,
    },
    ref
  ) => {
    const { current } = ref;

    const [slideValue, setSlideValue] = useState(slideValueDefault);

    const handleClick = () => {
      console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      if (setLeverageValue) {
        setLeverageValue(slideValue);
      }
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (current && current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="string"
          color="primary"
          ref={ref}
          aria-label="split button"
        >
          <Button onClick={handleClick}>
            {!isSlider ? options[selectedIndex] : `${slideValue}x`}
          </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <PopperDialogue
          placement="bottom-start"
          open={open}
          anchorEl={current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: /bottom/g.test(placement)
                  ? 'center top'
                  : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) =>
                      isSlider ? (
                        <ModifiedMenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          <NumberSlider
                            slideValue={slideValue}
                            setSlideValue={setSlideValue}
                          />
                        </ModifiedMenuItem>
                      ) : (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </PopperDialogue>
      </>
    );
  }
);

const PopperDialogue = styled(Popper)`
  z-index: 10;
`;

const ModifiedMenuItem = styled(MenuItem)`
  background-color: #fff !important;
`;

const SliderBox = styled(Box)`
  width: 300px;
  padding: 0 40px 0 40px;
  font-size: 12px;
`;

DropButton.defaultProps = {
  isSlider: false,
  setLeverageValue: null,
  slideValueDefault: 1,
};

DropButton.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isSlider: PropTypes.bool.defaultValue,
  setLeverageValue: PropTypes.any.defaultValue,
  slideValueDefault: PropTypes.any.defaultValue,
};

NumberSlider.propTypes = {
  slideValue: PropTypes.number.isRequired,
  setSlideValue: PropTypes.func.isRequired,
};
