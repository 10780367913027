import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton } from './Controls';
import { useMediaQuery } from '../../hooks/useMediaQuery';
// import { useAnalytics } from '../../providers/AnalyticsProvider';

import { setExchangeApi } from '../../store/actions';
import { DropButton } from '../common/DropdownButton';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

const optionsExchangeApi = ['binance', 'bybit'];

export const SetExchangeApi = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  const dispatch = useDispatch();

  const [apiName, setApiName] = useState('');
  const [pubKey, setPublicKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [password, setPassword] = useState('');

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // const { exchanges } = useSelector(
  //   (state) => state.personalDetails.personalDetails
  // );

  // const optionsExchangeApi = exchanges?.length
  //   ? exchanges.map((ex) => ex.name)
  //   : ['No exchange API'];

  const handleFormSubmit = () => {
    dispatch(
      setExchangeApi({
        name: apiName,
        pubkey: pubKey,
        secret: secretKey,
        exchange: optionsExchangeApi[selectedIndex],
        password,
      })
    );
    // Return to Defaults
    setApiName('');
    setPublicKey('');
    setSecretKey('');
    setPassword('');
  };

  return (
    <div className="content-page">
      <div className="content-page-container">
        <div className="header-page">
          <div className="header-page-text">Add Exchange API keys</div>
        </div>
        <div className="content-page-form">
          <TextField
            className={classes.input}
            label="Public API Key"
            onChange={(e) => setPublicKey(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            required
          />
          <TextField
            className={classes.input}
            label="Secret API Key"
            onChange={(e) => setSecretKey(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            className={classes.input}
            label="Password (optional)"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className={classes.input}
            label="API Name Identifier"
            onChange={(e) => setApiName(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            required
          />
          <Typography>Select Correct Exchange:</Typography>
          <Box mt="14px" />
          <DropButton
            open={open}
            ref={anchorRef}
            selectedIndex={selectedIndex}
            setOpen={setOpen}
            setSelectedIndex={setSelectedIndex}
            options={optionsExchangeApi}
          />
          <Box mt="48px" />
          <PrimaryButton
            color="primary"
            style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
            variant="contained"
            onClick={handleFormSubmit}
          >
            Add new key
          </PrimaryButton>
          <Box mt="48px" />
        </div>
      </div>
    </div>
  );
};
