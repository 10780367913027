import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import { CssBaseline } from '@material-ui/core';

import {
  StylesProvider,
  ThemeProvider as ThemeProviderMui,
} from '@material-ui/core/styles';

import theme from '../common/materialTheme';

const ThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <ThemeProviderMui theme={theme}>
      <CssBaseline />
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeProviderMui>
  </StylesProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
