import React from 'react';
import PropTypes from 'prop-types';

export const ComplianceCard = ({ code, message, context, type }) => {
  return (
    <div
      className={`modal-compliance-item ${
        type === 'error'
          ? 'modal-compliance-item-error'
          : 'modal-compliance-item-warning'
      }`}
    >
      <div className="modal-compliance-item-header">
        {type === 'error' ? (
          <img
            className="modal-compliance-item-ico"
            alt="error"
            src="error_circle.png"
          />
        ) : (
          <img
            className="modal-compliance-item-ico"
            alt="warning"
            src="warning_circle.png"
          />
        )}
        <div>{code}</div>
      </div>
      <div className="modal-compliance-item-content">
        {`${message} ${context}`}
      </div>
    </div>
  );
};

ComplianceCard.propTypes = {
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  context: PropTypes.string,
};

ComplianceCard.defaultProps = {
  context: '',
};
